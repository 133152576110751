<template>
  <div class="designSprint-page">
    <div class="designSprint-section">
      <div class="web pt-20 lg:px-24 md:px-8">
        <p class="web-title font-extrabold text-6xl border-b-4 pb-2"  v-html=" $t('designSprint')">
        </p>
      </div>
      <section class="relative bg-primaryBlue py-20" id="macbook">
        <div
          class="absolute top-0 left-0 right-0 bottom-auto -mt-20 w-full overflow-hidden"
          style="height: 80px"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xlmns="http//www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="fill-current text-black"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto bg-primaryBlue px-4">
          <div class="flex flex-wrap items-center">
            <div class="ml-auto mr-auto w-full px-4 md:w-4/12">
              <img
              :src="images.web"
                class="max-w-full rounded-lg shadow-lg"
                style="width: 400px"
                alt="Macbook"
                original
              />
            </div>

            <div class="ml-auto mr-auto w-full px-4 md:w-5/12">
              <div class="md:pr-12">
                <div
                  class="mb-6 inline-flex h-16 w-16 items-center justify-center rounded-full bg-white p-3 text-center text-black shadow-lg"
                >
                  <i class="fas fa-laptop text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold text-white">
                  Cartographie et Modélisation.
                </h3>
                <p class="mt-4 text-lg leading-relaxed text-gray-300">
                  La cartographie et la modélisation des processus métier sont des outils puissants pour améliorer la performance des organisations en améliorant la compréhension des processus, en identifiant les points de blocage et en optimisant les processus métier.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="web-detail py-12 lg:px-24 md:px-8">
        <div class="font-hairline text-2xl leading-relaxed mb-8">
          {{ $t('designDesc') }}
        </div>
        <div class="font-hairline text-2xl leading-relaxed mb-8">
          {{ $t('weCreateaUser') }}
        </div>
        <div class="font-hairline text-2xl leading-relaxed mb-8">
          {{ $t('beyondTheIntroduction') }}
        </div>
        <div class="font-hairline text-2xl leading-relaxed mb-8">
          {{ $t('userExperience') }}
        </div>
        <div class="font-hairline text-2xl leading-relaxed mb-8">
          {{ $t('ourStreamlined') }}
        </div>
        <div class="font-hairline text-2xl leading-relaxed mb-8">
          {{ $t('weCarryOut') }}
        </div>
      </div>
      <section class="text-gray-600 body-font relative">
        <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-indigo-500">Contactez-nous</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base">N’hésitez pas à nous contacter ☺️.</p>
    </div>
    <form ref="form" @submit.prevent="sendEmail">
    <div class="lg:w-1/2 md:w-2/3 mx-auto">
      <div class="flex flex-wrap -m-2">
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="name" class="leading-7 text-sm text-gray-600">Nom</label>
            <input type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-full">
          <div class="relative">
            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
        </div>
        <div class="p-2 w-full">
          <button type="submit" class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Envoyez</button>
        </div>
      </div>
    </div>
  </form>
  </div>
</section>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import web from "../assets/images/webp/process.webp"
export default {
  name: 'Modelisation',
  components: { },
  data () {
    return {
      images: { web },
      name: '',
      email: '',
      message: ''

    }
  },
  created: function () {
    document.title = "BforProcess | Leader de l'approche processus"
    document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          name: this.name,
          email: this.email,
          message: this.message
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/sass/variables.scss";
.fill-current{
  color: #607d8b;
}
  .bg-primaryBlue{
    background: #023C61;
  }
  .web-title {
    color:  #607d8b !important;
    border-bottom-color:  #607d8b !important;
    width: max-content;
  }
  @media screen and (max-width: 600px) {
    .web, .web-detail {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .web-title {
      font-size: 1.5rem;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .web, .web-detail {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .web-title {
      font-size: 2.5rem;
    }
  }
</style>
