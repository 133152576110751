/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_twitter': {
    width: 33,
    height: 32,
    viewBox: '0 0 33 32',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M16.96 0c-8.835 0-16 7.165-16 16s7.165 16 16 16 16-7.165 16-16-7.165-16-16-16zm7.305 12.475c.008.158.01.316.01.475 0 4.852-3.693 10.448-10.448 10.449-2.074 0-4.004-.608-5.63-1.65.288.034.58.05.877.05 1.72 0 3.304-.586 4.561-1.571a3.677 3.677 0 01-3.43-2.551 3.653 3.653 0 001.658-.063 3.673 3.673 0 01-2.946-3.647c.495.275 1.06.441 1.663.46a3.669 3.669 0 01-1.634-3.057c0-.673.182-1.304.497-1.846a10.426 10.426 0 007.57 3.837 3.673 3.673 0 016.258-3.35 7.365 7.365 0 002.332-.89 3.687 3.687 0 01-1.615 2.03 7.325 7.325 0 002.11-.578 7.464 7.464 0 01-1.833 1.902z" _fill="#fff"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" transform="translate(.96)" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
