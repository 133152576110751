/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'seting': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.642 1.273A1.05 1.05 0 019.667.45h4.666c.492 0 .919.342 1.025.822l.526 2.368c.494.23.967.502 1.412.815l2.315-.728c.47-.148.979.05 1.225.476l2.333 4.042a1.05 1.05 0 01-.2 1.298l-1.787 1.641a9.276 9.276 0 010 1.63l1.788 1.64c.362.333.445.873.199 1.3l-2.333 4.04a1.05 1.05 0 01-1.225.477l-2.314-.728a9.197 9.197 0 01-1.413.817l-.526 2.368a1.05 1.05 0 01-1.025.822H9.667a1.05 1.05 0 01-1.025-.822l-.526-2.368a9.208 9.208 0 01-1.412-.815l-2.315.729a1.05 1.05 0 01-1.225-.477L.831 15.756a1.05 1.05 0 01.2-1.299l1.787-1.64a9.281 9.281 0 010-1.63L1.03 9.543a1.05 1.05 0 01-.199-1.298l2.333-4.041a1.05 1.05 0 011.225-.477l2.314.729a9.207 9.207 0 011.413-.817l.526-2.367zm1.867 1.277l-.459 2.07a1.05 1.05 0 01-.643.751c-.666.261-1.29.621-1.85 1.069a1.05 1.05 0 01-.97.181l-2.025-.637L3.07 8.567l1.564 1.436c.257.236.38.585.328.93a7.181 7.181 0 000 2.137 1.05 1.05 0 01-.329.93l-1.563 1.435 1.49 2.583 2.025-.638a1.05 1.05 0 01.971.182 7.11 7.11 0 001.85 1.067c.325.127.566.409.642.75l.46 2.071h2.982l.46-2.07a1.05 1.05 0 01.642-.75 7.102 7.102 0 001.849-1.07 1.05 1.05 0 01.972-.182l2.024.637 1.491-2.582-1.563-1.435a1.05 1.05 0 01-.329-.93 7.175 7.175 0 000-2.138 1.05 1.05 0 01.329-.93l1.563-1.435-1.49-2.582-2.025.637a1.05 1.05 0 01-.971-.181 7.109 7.109 0 00-1.85-1.068 1.05 1.05 0 01-.642-.75l-.46-2.07h-2.982z" _fill="#7994BC"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12 9.55a2.45 2.45 0 100 4.9 2.45 2.45 0 000-4.9zM7.45 12a4.55 4.55 0 119.1 0 4.55 4.55 0 01-9.1 0z" _fill="#7994BC"/>'
  }
})
