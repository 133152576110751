/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_vector3': {
    width: 310.667,
    height: 196,
    viewBox: '0 0 233 147',
    data: '<path pid="0" d="M0 4.4v4.5l84.8.3 84.7.3 6.7 2.3c14 4.7 27.2 16.2 33.9 29.4 5.9 11.7 6.2 14 6.7 53.5l.4 36.2-4.1.3-4.1.3 5.2 7.7c2.9 4.3 5.8 7.7 6.3 7.7 1 0 12.5-14.1 12.5-15.3 0-.3-1.8-.6-3.9-.6h-4l-.3-39.3-.3-39.2-2.8-7.1c-4.1-10.9-7.5-16.2-15.3-24.4-8.3-8.6-16.5-13.9-27.9-17.8l-8-2.7L85.3.2 0-.1v4.5z"/>'
  }
})
