<template>
<div class="header bg-blueWhite">
  <div class="header-web bg-blueWhite header h-20 bg-white text-black flex items-center md:pl-16 md:pr-16 pl-8 pr-8">
    <div class="flex-one w-1/4">
      <router-link to="/">
        <img :src="images.logo" alt="logo" class="" original> 
     </router-link>
    </div>
    <div class="flex-grow flex justify-end text-black text-lg items-center">
      <router-link to="/" class="ml-6 mr-6 text-black text-semibold no-underline"> Accueil </router-link>
      <router-link to="/team" class="ml-6 mr-6 text-black text-semibold no-underline"> {{ $t('competitions1') }} </router-link>
     <!-- <router-link to="/contact" class="ml-6 mr-6 text-black text-semibold no-underline"> {{ $t('signIn1') }} </router-link> -->
     <router-link to="/blogs" class="ml-6 mr-6 text-black text-semibold no-underline"> Blog </router-link> 
      <router-link v-if="user" class="link" :to="{ name: 'CreatePost' }">Create Post</router-link>
      <div class="button-create">
        <router-link to="/contact"><CreateButton label="signIn1"></CreateButton></router-link>
      </div>
    </div>
    <div v-if="user" @click="toggleProfileMenu" absolute class="profile" ref="profile">
      <span>{{ this.$store.state.profileInitials }}</span>
      <div v-show="profileMenu" class="profile-menu">
        <div class="options">
          <div @click="signOut" class="option">
                <signOutIcon class="icon" />
          <p>Sign Out</p>
          </div>
          <div class="option">
                <router-link class="option" :to="{ name: 'Profile' }">
                  <userIcon class="icon" />
                  <p>Profile</p>
                </router-link>
              </div>
        </div>
        <div class="info">
          <p class="initials">{{ this.$store.state.profileInitials }}</p>
          <div class="right">
            <p>{{ this.$store.state.profileFirstName }} {{ this.$store.state.profileLastName }}</p>
            <p>{{ this.$store.state.profileUsername }}</p>
            <p>{{ this.$store.state.profileEmail }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-mobile h-20 bg-blueWhite">
    <div class="header-container pl-4 pr-4 flex items-center">
      <div class="flex-one w-1/2">
        <router-link to="/">
        <img :src="images.logo" alt="logo" class="" original> 
        </router-link>
      </div>
      <div class="flex-one w-1/2  h-full  burger-section items-center flex justify-end mr-4">
        <div class="burger flex flex-col " v-if="!showMobileMenu" @click="showMobileMenu = !showMobileMenu">
          <div class="line m-1"></div>
          <div class="line m-1"></div>
          <div class="line m-1"></div>
        </div>
        <div class="close-burger flex flex-col" v-if="showMobileMenu" @click="showMobileMenu = !showMobileMenu">
          <div class="line "></div>
          <div class="line "></div>
        </div>
      </div>
    </div>
    <div class="shadow absolute w-full mobile-menu-items mr-12 bg-white modal-body" :class="{ 'mobile-menu-items-active': showMobileMenu}">
      <slot name="body">
      <div class="pl-4 pr-4 pt-4 w-full">
        <div class="text-right w-full flex items-center ">
          <router-link to="/team" class="ml-6 mr-6 text-black text-semibold no-underline"> {{ $t('competitions1') }} </router-link>
        </div>
     <!--   <div class="flex-one text-right w-full h-16 flex items-center ">
          <router-link to="/contact" class="ml-6 mr-6 text-black text-semibold no-underline"> {{ $t('signIn1') }} </router-link>
           </div> -->
          <router-link to="/blogs" class="flex-one text-right w-full h-16 flex items-center ml-6 mr-6 text-black text-semibold no-underline"> Blog </router-link> 
        <div class="flex-one text-right  h-16 items-center mt-4">
        <router-link to="/contact"><CreateButton label="signIn1"></CreateButton></router-link>
        </div>
      </div>
    </slot>
    </div>
  </div>
</div>
</template>

<script>
import CreateButton from './CreateButton'
import logo from '../../assets/images/webp/logo.webp'
import userIcon from '../../assets/cons/user-alt-light.svg';
import adminIcon from '../../assets/cons/user-crown-light.svg';
import signOutIcon from '../../assets/cons/sign-out-alt-regular.svg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
export default {
  name: 'Header',
  components: { CreateButton, userIcon, adminIcon, signOutIcon},
  data () {
    return {
      images:{
        logo
      },
      showMobileMenu: false,
      profileMenu:null,
    }
  },
  methods: {
    toggleProfileMenu(e) {
      if (e.target === this.$refs.profile) {
        this.profileMenu = !this.profileMenu;
      }
    },
    signOut() {
      firebase.auth().signOut();
      window.location.reload();
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.header-web{
background-color: #F7F9FE;
}

.header-mobile  {
  display: none;
  background-color: #F7F9FE !important;
}

.burger {
  cursor: pointer;
  .line {
    height: .1rem;
    width: 2rem;
    background-color: $primaryBlue;
  }
}

.profile {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        background-color: #303030;
        position: relative;

        span {
          pointer-events: none;
        }

        .profile-menu {
          position: absolute;
          top: 60px;
          right: 0;
          width: 250px;
          background-color: #303030;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);

          .info {
            display: flex;
            align-items: center;
            padding: 15px;
            border-bottom: 1px solid #fff;

            .initials {
              position: initial;
              width: 40px;
              height: 40px;
              background-color: #fff;
              color: #303030;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }

            .right {
              flex: 1;
              margin-left: 24px;

              p:nth-child(1) {
                font-size: 14px;
              }

              p:nth-child(2),
              p:nth-child(3) {
                font-size: 12px;
              }
            }
          }

          .options {
            padding: 15px;

            .option {
              text-decoration: none;
              color: #fff;
              display: flex;
              align-items: center;
              margin-bottom: 12px;

              .icon {
                width: 18px;
                height: auto;
              }

              p {
                font-size: 14px;
                margin-left: 12px;
              }

              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
.close-burger  {
  cursor: pointer;
  .line {
    height: .2rem;
    width: 2rem;
    background-color: $primaryBlue;
  }
  .line:first-child {
    transform: rotate(-45deg);
  }
  .line:last-child {
    transform: rotate(45deg);
  }
}

.mobile-menu-items {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
  z-index: 9999;
}
.mobile-menu-items-active {
  transform: scaleY(1);
}

@media only screen and (max-width: 600px) {
  .header-mobile {
    display: block;
    z-index: 9999;
    background-color: white;
  }
  .header-web {
    display: none;
  }
}
</style>
