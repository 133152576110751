/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<g opacity=".3" _stroke="#048A53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M2.5 7a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2h-16a2 2 0 01-2-2V7z"/><path pid="1" d="M12.5 15a3 3 0 100-6 3 3 0 000 6zM2.5 9a4 4 0 004-4m12 14a4 4 0 014-4"/></g>'
  }
})
