/*
App
Created at 09/04/2021 11:44
Author: Khaliq ALI
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import install from './install'
import store from './store'
import AOS from 'aos'
import Vue2Editor from 'vue2-editor'
import 'aos/dist/aos.css'
import Meta from 'vue-meta'

Vue.use(Meta)
Vue.use(Vue2Editor)
Vue.use(install)
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  mounted () {
    AOS.init()
  }
}).$mount('#app')
