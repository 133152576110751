<template>
  <div class="mobileDevelopment-page">
    <Header></Header>
    <div class="mobileDevelopment-section">
      <div class="web pt-20 lg:px-24 md:px-8">
        <p class="web-title font-extrabold text-6xl border-b-4 pb-2" v-html="$t('mobileDevelopment')">
        </p>
      </div>
      <div class="web-detail bg-gray-100  lg:px-24 md:px-8">
        <div class="font-hairline text-2xl leading-relaxed mb-8">
          <div class="flex flex-wrap items-center">
            <div class="ml-auto mr-auto w-full px-4 md:w-5/12">
              <p class="mt-4  text-xl font-light leading-relaxed text-gray-700">
                L'accompagnement BPM permet aux entreprises de gagner en efficacité, de réduire les coûts et de s'adapter plus facilement aux changements. Il est particulièrement utile pour les entreprises qui ont des processus complexes ou qui souhaitent améliorer leur performance globale.
              </p>
              <p class="mt-4 text-lg text-xl font-light leading-relaxed text-gray-700">
                Dans ce contexte, un "accompagnement" désigne probablement un service ou un soutien fourni pour aider à la mise en œuvre et à la gestion du BPM au sein d'une organisation. Il peut s'agir de conseil, de formation et de soutien continu pour aider une entreprise à améliorer ses processus métier et à accroître son efficacité.
              </p>
            </div>

            <div class="ml-auto mr-auto w-full px-4 md:w-4/12">
              <div class="aspect-w-16 aspect-h-9">
  <iframe src="https://www.youtube.com/embed/du6KEQNXL74" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
            </div>
          </div>
        </div>
      </div>
      <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">1</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">Cartographie et modélisation</h2>
          <p class="leading-relaxed">Il s'agit de comprendre les processus métier de l'entreprise, leur fonctionnement, les points de blocage et mettre en place des solutions pour améliorer les processus, par exemple en automatisant certaines tâches ou en simplifiant les étapes.</p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">2</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">Analyse et optimisation</h2>
          <p class="leading-relaxed">Il s'agit de surveiller les processus pour s'assurer qu'ils fonctionnent efficacement et de détecter les éventuels problèmes.</p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">3</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <circle cx="12" cy="5" r="3"></circle>
            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">Exécution</h2>
          <p class="leading-relaxed">Il s'agit de mettre en place des outils pour faciliter la gestion des processus, par exemple un logiciel de BPM.</p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">4</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">Suivi et surveillance</h2>
          <p class="leading-relaxed">Il s'agit de mettre en place des outils pour faciliter la gestion des processus, par exemple un logiciel de BPM.</p>
        </div>
      </div>
    </div>
  </div>
</section>
      <section class="text-gray-600 body-font relative">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-indigo-500">Contactez-nous</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base">N’hésitez pas à nous contacter ☺️.</p>
    </div>
    <form ref="form" @submit.prevent="sendEmail">
    <div class="lg:w-1/2 md:w-2/3 mx-auto">
      <div class="flex flex-wrap -m-2">
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="name" class="leading-7 text-sm text-gray-600">Nom</label>
            <input type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-full">
          <div class="relative">
            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
        </div>
        <div class="p-2 w-full">
          <button type="submit" class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Envoyez</button>
        </div>
      </div>
    </div>
  </form>
  </div>
</section>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import mobile from "../assets/images/a.jpg"
export default {
  name: '',
  components: {},
  data () {
    return {
      images: { mobile },
      name: '',
      email: '',
      message: ''

    }
  },
  created: function () {
    document.title = "BforProcess | Leader de l'approche processus"
    document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          name: this.name,
          email: this.email,
          message: this.message
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/sass/variables.scss";

  .bg-primaryBlue{
    background: #023C61;
  }
  .web-title {
    color: $devs_main-color;
    border-bottom-color: $devs_main-color;
    width: max-content;
  }

  @media screen and (max-width: 600px) {
    .web, .web-detail {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .web-title {
      font-size: 1.5rem;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .web, .web-detail {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .web-title {
      font-size: 2.5rem;
    }
  }
</style>
