/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'down-white': {
    width: 13,
    height: 8,
    viewBox: '0 0 13 8',
    data: '<path pid="0" d="M1.5 1.5l5 5 5-5" _stroke="#fff" stroke-width="2"/>'
  }
})
