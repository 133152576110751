/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_download': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" d="M29 14.5C29 22.508 22.508 29 14.5 29S0 22.508 0 14.5 6.492 0 14.5 0 29 6.492 29 14.5z" _fill="#5F01D3"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M18.821 15.196l-3.889 3.637a.641.641 0 01-.864 0l-3.889-3.637a.546.546 0 010-.808.641.641 0 01.864 0l2.846 2.661V7h1.222v10.049l2.846-2.661a.641.641 0 01.864 0 .545.545 0 010 .808z" _fill="#fff"/>'
  }
})
