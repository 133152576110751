/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_facebook_share': {
    width: 42,
    height: 41,
    viewBox: '0 0 42 41',
    data: '<path pid="0" d="M36.197 4.483H6.737a1.28 1.28 0 00-1.28 1.28v29.46c0 .708.572 1.28 1.28 1.28h29.46a1.28 1.28 0 001.28-1.28V5.763a1.28 1.28 0 00-1.28-1.28zm-3.698 9.346H29.94c-2.005 0-2.394.953-2.394 2.354v3.085h4.787L31.71 24.1h-4.163v12.404h-4.99v-12.4H18.38v-4.835h4.175v-3.563c0-4.134 2.526-6.388 6.216-6.388 1.77 0 3.286.132 3.73.192v4.319H32.5z" _fill="#4F4F4F"/>'
  }
})
