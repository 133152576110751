/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_plus': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 .333c.46 0 .833.373.833.834v5h5a.833.833 0 010 1.666h-5v5a.833.833 0 01-1.666 0v-5h-5a.833.833 0 110-1.666h5v-5c0-.46.373-.834.833-.834z" _fill="#fff"/>'
  }
})
