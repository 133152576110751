/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_vector2': {
    width: 537.333,
    height: 118.667,
    viewBox: '0 0 403 89',
    data: '<path pid="0" d="M56 2.3C27.7 10.8 8.1 36.5 8 65.2V73H3.9c-2.2 0-3.9.4-3.7.8.7 1.6 10.3 15.1 10.8 15.1.3 0 3.3-3.5 6.7-7.7l6.2-7.7-4.1-.3-4.1-.3.7-7.7c1-10.6 2.3-15.5 6.3-24.1C27.3 31.5 38.5 20 48 15.2 62.3 8 46.9 8.6 234.3 8.3L403 7.9V0L233.3.1H63.5L56 2.3z"/>'
  }
})
