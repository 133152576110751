/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_success-recovery': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<circle pid="0" cx="44" cy="44" r="44" _fill="#F3F5FA"/><path pid="1" d="M44.188 30.313a13.874 13.874 0 0113.865 14.36 5.763 5.763 0 00-2.303-.476v-.01a11.562 11.562 0 10-9.243 11.332v2.35a13.877 13.877 0 01-15.442-18.172 13.873 13.873 0 0113.122-9.385zm-1.735 16.284l6.637-6.655a1.156 1.156 0 011.771 1.473l-.134.162-7.455 7.474a1.157 1.157 0 01-1.476.134l-.161-.132-4.047-4.047a1.156 1.156 0 011.475-1.769l.162.132 3.228 3.228zm9.828 4.523c-.053 0-.108 0-.162.005h-.994a2.312 2.312 0 00-2.313 2.313v6.937a2.312 2.312 0 002.313 2.313h9.25a2.312 2.312 0 002.313-2.313v-1.156l.006-4.63a3.43 3.43 0 00-.007-.217v-.934a2.312 2.312 0 00-2.312-2.313h-.983a3.427 3.427 0 00-.166-.005h-.007v-1.142a3.469 3.469 0 00-6.938 0v1.142zm2.313-1.142a1.156 1.156 0 112.312 0v1.142h-2.312v-1.142zm1.156 8.084a1.156 1.156 0 110-2.312 1.156 1.156 0 010 2.313z" _fill="#048A53"/>'
  }
})
