/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_shape1': {
    width: 433,
    height: 384,
    viewBox: '0 0 433 384',
    data: '<path pid="0" d="M11.494 119.047C14.14 82.318 41.515 52.14 77.813 45.938L339.197 1.275c54.785-9.361 102.187 38.619 92.162 93.286l-40.896 223.021c-7.582 41.345-45.867 69.771-87.637 65.069l-231.46-26.055C28.704 351.794-2.563 314.172.523 271.35l10.972-152.303z" _fill="#F6F6F6"/>'
  }
})
