<template>
  <div class="flotte-button">
    <button
      class="btn flotte-button-real py-3 px-6 font-normal"
      :style="{backgroundColor: background, color: fontcolor}"
      :class="{'border-dotted': borderDot,'border': !borderDot}"
    >
      <h4
        v-if="!isLoading"
        class="font-extrabold text-xl"
      >
        {{ label }}
      </h4>
    </button>
  </div>
</template>

<script>
export default {
  name: 'flotte-button',
  props: {
    label: {
      type: String,
      default: 'Button'
    },
    // TODO HERE IF HE WANT PREVIOUS COLOR
    background: {
      type: String,
      default: '#19C064'
    },
    fontcolor: {
      type: String,
      default: '#FFFFFF'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    borderDot: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      color: '#FFFFFF'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/style/sass/variables.scss";
  .border-dotted {
    // border: solid $devs_green 1px;
  }
  button {
    border-width: 0;
  }
</style>
