/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rh': {
    width: 24,
    height: 20,
    viewBox: '0 0 24 20',
    data: '<path pid="0" d="M3.833.667a3.5 3.5 0 00-3.5 3.5v11.666a3.5 3.5 0 003.5 3.5h16.334a3.5 3.5 0 003.5-3.5V4.167a3.5 3.5 0 00-3.5-3.5H3.833zm-1.166 3.5C2.667 3.52 3.188 3 3.833 3h16.334c.645 0 1.166.521 1.166 1.167v11.666c0 .646-.521 1.167-1.166 1.167H3.833a1.165 1.165 0 01-1.166-1.167V4.167zm5.826 2.916a1.75 1.75 0 10-3.501.001 1.75 1.75 0 003.5 0zm-4.66 4.86c0 .536.434.973.974.973h2.045a3.787 3.787 0 012.377-2.683A1.924 1.924 0 008.307 10h-2.53a1.945 1.945 0 00-1.944 1.943zm13.315.973h2.045a.973.973 0 00.974-.973A1.943 1.943 0 0018.223 10h-2.53c-.335 0-.649.084-.922.233a3.788 3.788 0 012.377 2.683zm-3.252-1.713a2.465 2.465 0 00-.438-.037h-2.916a2.633 2.633 0 00-2.476 1.75 2.58 2.58 0 00-.15.875c0 .485.39.875.876.875h6.416c.485 0 .875-.39.875-.875a2.58 2.58 0 00-.15-.875 2.633 2.633 0 00-2.037-1.713zM19 7.083a1.75 1.75 0 10-3.5.001 1.75 1.75 0 003.5 0zM12 10a2.336 2.336 0 002.333-2.333A2.336 2.336 0 0012 5.332a2.336 2.336 0 00-2.333 2.333A2.336 2.336 0 0012 10z" _fill="#7994BC"/>'
  }
})
