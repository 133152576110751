/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.667.667a7.5 7.5 0 015.86 12.18l3.062 3.064a.833.833 0 01-1.1 1.248l-.078-.07-3.063-3.062A7.5 7.5 0 117.667.667zm0 1.666a5.833 5.833 0 100 11.667 5.833 5.833 0 000-11.667z" _fill="#7F8896"/>'
  }
})
