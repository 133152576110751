<template>
  <div class="work-page">
    <work-text />
  </div>
</template>

<script>

import WorkText from '../components/helpers/work/work-text'
export default {
  name: 'work',
  components: { WorkText },
  created: function () {
    document.title = 'BforProcess | Tech Process Company'
    document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/sass/variables.scss";
</style>
