/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye-open': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 3c2.69 0 5.066 1.824 7.133 5.358l.155.268L16.5 9l-.367.642C14.067 13.176 11.691 15 9 15c-2.69 0-5.067-1.824-7.133-5.358l-.155-.268L1.5 9l.212-.374C3.815 4.916 6.242 3 9 3zm0 1.5C6.973 4.5 5.046 5.937 3.229 9 5.046 12.063 6.973 13.5 9 13.5c1.972 0 3.847-1.357 5.615-4.241L14.771 9l-.156-.258C12.844 5.856 10.969 4.5 9 4.5zm0 2.25a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5z" _fill="#949BA7"/>'
  }
})
