/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock': {
    width: 23,
    height: 22,
    viewBox: '0 0 23 22',
    data: '<path pid="0" d="M11.5 21c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z" _stroke="#909090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M14.5 15l-2.414-2.414a2 2 0 01-.586-1.414V5" _stroke="#909090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
