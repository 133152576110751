/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_facebook copy': {
    width: 40,
    height: 41,
    viewBox: '0 0 40 41',
    data: '<circle pid="0" opacity=".4" cx="19.914" cy="20.524" r="19.203" _stroke="#E0E0E0" stroke-width="1.422"/><path pid="1" d="M18.174 27.637v-7.008h-1.816v-2.524h1.816V15.95c0-1.693 1.073-3.249 3.544-3.249 1 0 1.74.098 1.74.098l-.058 2.356s-.755-.007-1.578-.007c-.891 0-1.034.42-1.034 1.115v1.842h2.683l-.117 2.524h-2.566v7.008h-2.614z" _fill="#000"/>'
  }
})
