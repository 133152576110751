/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_shape2': {
    width: 431,
    height: 415,
    viewBox: '0 0 431 415',
    data: '<path pid="0" d="M13.965 73.87C19.223 47.114 39.349 26.4 65.913 20.24c80.834-18.743 255.608-47.14 326.29 36.184 60.587 71.422 36.23 214.954 19.721 283.973-5.893 24.637-24.522 43.665-49.008 50.158-74.741 19.818-238.563 52.058-312.838-21.052-69.875-68.778-50.741-221.19-36.113-295.632z" _fill="#F6F6F6"/>'
  }
})
