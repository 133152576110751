<template>
    <div class="coming-soon">
        <div class="flex items-center justify-center h-screen bg-gray-200">
  <div class="container">
    <div class="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
      <div class="text-center">
        <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          BFor<span class="text-indigo-600">Process</span>
        </h2>
        <h3 class='text-xl md:text-3xl mt-10'>Coming Soon</h3>
        <p class="text-md md:text-xl mt-10"><a class="hover:underline" href="#">BforProcess</a>  est un cabinet international créé par des experts en BPM (Business Process Management) et qui a pour objectif principal la vulgarisation de l’approche processus, et l’accompagnement des entreprises dans leur projet de transformation.</p>
      </div>
      <div class="flex flex-wrap mt-10 justify-center">
        <div class="m-3">
          <a href="https://www.facebook.com/profile.php?id=100088829275144&sk=about" title="Bforprocess On Facebook"
             class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
            <span class="mx-auto">Facebook</span>
          </a>
        </div>
        <div class="m-3">
          <a href="https://twitter.com/bforprocess" title="Bforprocess On Twitter"
             class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
            <span class="mx-auto">Twitter</span>
          </a>
        </div>
        <div class="m-3">
          <a href="https://www.youtube.com/channel/UChU4hMiUyCoC3CCSUFzIk5A" title="Bforprocess On Pinterest"
             class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-red-600 hover:border-red-600 hover:bg-red-600 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
            <span class="mx-auto">Youtube</span>
          </a>
        </div>
        <div class="m-3">
          <a href="https://www.linkedin.com/company/bforprocess/" title="Bforprocess On Twitter"
             class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
            <span class="mx-auto">Linkedin</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  </div>  
    </div>
    </template>
    
    <script>
    export default {
      name: 'coming-soon',
      components: { },
      created: function () {
        document.title = 'BforProcess | Tech Process Company'
        document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
      }
    }
    </script>
    
    <style lang="scss" scoped>
      @import "../assets/style/sass/variables.scss";
    </style>
    