/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<rect pid="0" width="36" height="36" rx="6" _fill="#F1FFF9"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.834 21.333h8.333a4.167 4.167 0 014.167 4.167.833.833 0 01-1.661.097l-.01-.244a2.5 2.5 0 00-2.35-2.349L22.168 23h-8.333a2.5 2.5 0 00-2.5 2.5.833.833 0 01-1.667 0 4.167 4.167 0 013.986-4.163l.18-.004h8.334-8.333zM18 9.667a5 5 0 110 10 5 5 0 010-10zm0 1.666A3.333 3.333 0 1018 18a3.333 3.333 0 000-6.667z" _fill="#108455"/>'
  }
})
