/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.992 11.994L13.054 0v7.184h-1.27a11.706 11.706 0 00-8.333 3.452A11.707 11.707 0 000 18.968V24l2.1-2.3a15.063 15.063 0 0110.954-4.896v7.183l10.938-11.993zm-22.586 8.39v-1.416a10.31 10.31 0 013.04-7.338 10.31 10.31 0 017.338-3.04h2.676V3.63l7.629 8.364-7.629 8.364v-4.961H13.2c-4.44 0-8.709 1.811-11.793 4.987z" _fill="#000"/>'
  }
})
