/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_shape4': {
    width: 514,
    height: 432,
    viewBox: '0 0 514 432',
    data: '<path pid="0" d="M485.573 375.824c-96.335 107.38-377.585 46.132-470.983-63.97-53.635-63.228 52.115-85.431 130.75-93.221 41.812-4.143 76.483-36.772 83.348-78.224 13.077-78.95 43.327-184.543 108.024-120.861 94.348 92.868 237.332 257.663 148.861 356.276z" _fill="#F6F6F6"/>'
  }
})
