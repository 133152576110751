/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'green-mail': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" d="M22.5 22.5h-18a2.25 2.25 0 01-2.25-2.25V6.652A2.25 2.25 0 014.5 4.5h18a2.25 2.25 0 012.25 2.25v13.5a2.25 2.25 0 01-2.25 2.25zM4.5 8.851V20.25h18V8.851l-9 5.999-9-5.998zm.9-2.101l8.1 5.4 8.1-5.4H5.4z" _fill="#048A53" opacity=".3"/>'
  }
})
