/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bag': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M10.708 4.375V2.917H7.792v1.458h2.916zM3.417 5.833v8.021h11.666v-8.02H3.417zm11.666-1.458c.81 0 1.459.649 1.459 1.458v8.021c0 .81-.65 1.459-1.459 1.459H3.417c-.81 0-1.459-.65-1.459-1.459l.008-8.02c0-.81.641-1.459 1.45-1.459h2.917V2.917c0-.81.65-1.459 1.459-1.459h2.916c.81 0 1.459.65 1.459 1.459v1.458h2.916z" _fill="#048A53" opacity=".3"/>'
  }
})
