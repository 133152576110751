/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_fleche': {
    width: 300,
    height: 300,
    viewBox: '0 0 225 225',
    data: '<path pid="0" d="M158.5 56l-5 5 21.8 21.8c11.9 11.9 21.7 22.1 21.7 22.5 0 .4-44.3.7-98.5.7H0v13h98.5c54.2 0 98.5.3 98.5.7 0 .4-9.8 10.6-21.7 22.5L153.5 164l5 5 5 5 30.8-30.8 30.7-30.7-30.8-30.8L163.5 51l-5 5z"/>'
  }
})
