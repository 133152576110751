/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_more': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 0c3.588 0 6.755 2.432 9.511 7.144l.206.357L20 8l-.489.856C16.755 13.568 13.588 16 10 16 6.412 16 3.245 13.568.489 8.856L.283 8.5 0 8l.283-.499C3.087 2.556 6.323 0 10 0zm0 2C7.297 2 4.728 3.916 2.305 8c2.423 4.085 4.992 6 7.695 6 2.63 0 5.129-1.81 7.487-5.655L17.695 8l-.208-.345C15.125 3.808 12.626 2 10 2zm0 3a3 3 0 110 6 3 3 0 010-6z" _fill="#000"/>'
  }
})
