<template>
  <div class="workText-section">
    <div
      class="text-intro py-20 leading-normal lg:px-24 md:px-8"
    >
      <div
        data-aos="fade-up" data-aos-offset="200" data-aos-delay="30" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="true" data-aos-anchor-placement="top-bottom"
      >
        <p class="begin text-5xl font-semibold">
          {{ $t('weBuild') }}
        </p>
        <p class="begin text-5xl font-semibold">
          {{ $t('usefulProduct') }}
        </p>
        <p class="begin text-5xl font-extrabold">
          {{ $t('makeYoursToo') }}
        </p>
      </div>
    </div>
    <div class="project-section py-16 lg:px-24 md:px-8">
      <div class="work-project flex justify-between">
        <work-project
          v-for="(index, key) in work_project"
          :key="index + key"
          :project="index"
          class="flex-1"
        />
      </div>
      <div class="work-project flex justify-between">
        <work-project
          v-for="(index, key) in work_project1"
          :key="index + key"
          :project="index"
          class="flex-1"
        />
      </div>
      <div class="work-project flex justify-between">
        <work-project
          v-for="(index, key) in work_project2"
          :key="index + key"
          :project="index"
          class="flex-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rema from '../../../assets/images/rema.png'
import ylomi from '../../../assets/images/ylomi.png'
import jimmo from '../../../assets/images/jimmo.png'
import arsen from '../../../assets/images/arsen.png'
import bureau from '../../../assets/images/bp.png'
import litee from '../../../assets/images/litee.png'
import journall from '../../../assets/images/journall.png'
import herwan from '../../../assets/images/qenbet.png'
import tatami from '../../../assets/images/tatami.png'
import WorkProject from './work-project'
export default {
  name: 'work-text',
  components: { WorkProject },
  data () {
    return {
      work_project: [
        {
          picture: rema,
          name: 'REMA',
          type: 'Mobile & Web',
          description: this.$t('remaDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: 'https://www.remaapp.com'
        },
        {
          picture: ylomi,
          name: 'Ylomi',
          type: 'Mobile & Web',
          description: this.$t('ylomiDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: 'https://ylomi.net'
        },
        {
          picture: jimmo,
          name: 'Jimmo',
          type: 'Mobile',
          description: this.$t('jimmoDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: ''
        }
      ],
      work_project1: [
        {
          picture: arsen,
          name: 'Arsen Assurance',
          type: 'Mobile & Web',
          description: this.$t('arsenDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: ''
        },
        {
          picture: bureau,
          name: 'Bureau de plaintes',
          type: 'UI/UX Design',
          description: this.$t('bureauDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: ''
        },
        {
          picture: litee,
          name: 'Litee',
          type: 'Web',
          description: this.$t('liteeDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: ''
        }
      ],
      work_project2: [
        {
          picture: journall,
          name: 'Journall',
          type: 'Web',
          description: this.$t('journallDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: ''
        },
        {
          picture: herwan,
          name: 'Herwan Qenbet',
          type: 'UI/UX Design',
          description: this.$t('herwanDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: ''
        },
        {
          picture: tatami,
          name: 'Tatami',
          type: 'UX Design & Web',
          description: this.$t('tatamiDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: ''
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables.scss";
  .text-intro {
    background-color: $devs_main-color;
    color: $white;
  }
  @media screen and (max-width: 600px) {
    .text-intro, .project-section {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .begin {
      font-size: 4rem;
      display: inline;
    }
    .work-project {
      display: block;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .text-intro, .project-section {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .begin {
      font-size: 4rem;
      display: inline;
    }
    .work-project {
      display: block;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .begin {
      display: inline;
    }
  }
</style>
