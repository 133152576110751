/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<path pid="0" d="M10.043 15.594l-4.863 2.5.925-5.27.093-.527-.388-.37-3.948-3.76 5.471-.778.517-.074.235-.466 2.417-4.788 2.416 4.788.236.466.517.074 5.47.777-3.947 3.761-.388.37.093.527.925 5.27-4.867-2.5-.457-.235-.457.235z" _stroke="#fff" stroke-width="2"/>'
  }
})
