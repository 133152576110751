/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" d="M13.313 21a7.675 7.675 0 0012.134 6.259l6.554 6.553 1.812-1.811-6.554-6.554a7.682 7.682 0 00-7.497-12.035A7.683 7.683 0 0013.312 21zm2.562 0A5.125 5.125 0 1121 26.125 5.131 5.131 0 0115.875 21z" _fill="#909090"/><path pid="1" d="M37.656 9.469H29.38l-2.19-3.267a1.274 1.274 0 00-1.064-.577h-10.25a1.275 1.275 0 00-1.063.577L12.62 9.469H4.344a1.285 1.285 0 00-1.282 1.281v21.781a1.285 1.285 0 001.282 1.282h11.531V31.25H5.625V12.031h7.688a1.274 1.274 0 001.063-.576l2.19-3.268h8.867l2.191 3.268a1.274 1.274 0 001.064.576h7.687v15.375h2.563V10.75a1.285 1.285 0 00-1.282-1.281z" _fill="#909090"/>'
  }
})
