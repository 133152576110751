/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_insta_share': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<path pid="0" d="M19.48 14.677a4.827 4.827 0 00-4.816 4.816 4.827 4.827 0 004.816 4.817 4.827 4.827 0 004.817-4.817 4.827 4.827 0 00-4.817-4.816zm14.446 4.816c0-1.994.018-3.97-.094-5.962-.112-2.312-.64-4.364-2.33-6.055-1.695-1.695-3.744-2.219-6.056-2.331-1.995-.112-3.971-.094-5.962-.094-1.995 0-3.971-.018-5.962.094-2.313.112-4.365.64-6.056 2.33-1.695 1.695-2.218 3.744-2.33 6.056-.112 1.995-.094 3.971-.094 5.962 0 1.991-.018 3.971.094 5.962.112 2.313.64 4.365 2.33 6.056 1.695 1.695 3.744 2.219 6.056 2.33 1.995.113 3.971.095 5.962.095 1.994 0 3.97.018 5.962-.094 2.312-.112 4.365-.64 6.056-2.33 1.694-1.696 2.218-3.744 2.33-6.057.116-1.99.094-3.967.094-5.962zM19.48 26.904a7.4 7.4 0 01-7.41-7.41 7.4 7.4 0 017.41-7.412 7.4 7.4 0 017.411 7.411 7.4 7.4 0 01-7.41 7.411zm7.715-13.394c-.958 0-1.731-.774-1.731-1.731 0-.958.773-1.73 1.73-1.73a1.729 1.729 0 011.6 2.393 1.729 1.729 0 01-1.6 1.068z" _fill="#4F4F4F"/>'
  }
})
