/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<rect pid="0" width="20" height="20" rx="4" _fill="#F3F5FA"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M17 12.3a.664.664 0 00-.228-.495l-6.222-5.6a.84.84 0 00-1.1 0l-6.222 5.6a.65.65 0 000 .99.84.84 0 001.1 0L10 7.69l5.672 5.105a.84.84 0 001.1 0A.664.664 0 0017 12.3" _fill="#343A44"/>'
  }
})
