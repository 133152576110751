/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 18,
    height: 17,
    viewBox: '0 0 18 17',
    data: '<g opacity=".3" _stroke="#048A53" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M9 9.208a2.125 2.125 0 100-4.25 2.125 2.125 0 000 4.25z"/><path pid="1" d="M9 1.417a5.667 5.667 0 00-5.667 5.666c0 1.34.285 2.217 1.063 3.188L9 15.583l4.604-5.312c.778-.97 1.062-1.848 1.062-3.188A5.667 5.667 0 009 1.417v0z"/></g>'
  }
})
