/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_facebook-land': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" _fill="#361075" d="M0 0h40v40H0z"/><path pid="1" d="M22.054 20.175l.285-2.036h-1.78v-1.322c0-.557.248-1.1 1.046-1.1h.81v-1.734s-.735-.137-1.438-.137c-1.466 0-2.424.975-2.424 2.74v1.553h-1.63v2.036h1.63V25.1h2.006v-4.924h1.495z" _fill="#fff"/>'
  }
})
