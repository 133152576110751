<template>
  <div class="digital-page">
    <div class="digital-section">
      <div class="web pt-20 lg:px-24 md:px-8">
        <p class="web-title font-extrabold text-6xl border-b-4 pb-2" v-html=" $t('digitalTransformation')">
        </p>
      </div>
      <div class=" flex flex-wrap items-center">
            <div class="ml-auto mr-auto w-full px-4 md:w-5/12">
              <p class="mt-4 text-2xl text-lg font-light leading-relaxed text-gray-700">
                La formation BPM (Business Process Management) est un service qui permet aux employés d'une entreprise de comprendre les principaux concepts et les meilleures pratiques de la gestion des processus métier. Il permet de mettre en place une méthodologie pour identifier, analyser, optimiser et surveiller les processus clés de l'entreprise.
Nous préparons aussi vos équipes à la certification <a href="https://www.omg.org/oceb-2/">OCEB 2</a>  qui est l'une des meilleures certifications dans le domaine du Business Process Management.              </p>
              <p class="mt-4 text-lg font-light leading-relaxed text-gray-700">
              </p>
            </div>

            <div class="ml-auto mr-auto w-full px-4 md:w-4/12">
              <div
                class="relative mb-6 flex w-full min-w-0 flex-col break-words rounded-lg bg-primaryBlue shadow-lg"
              >
                <img
                :src="images.web"
                  class="w-full rounded-t-lg align-middle"
                  alt="Ipad"
                  original
                />
              </div>
            </div>
          </div>
      <section class=" bg-gray-100 pb-20" id="ipad">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="w-full px-4 pt-6 text-center md:w-1/3 lg:pt-12">
              <div
                class="relative mb-8 flex w-full min-w-0 flex-col break-words rounded-lg bg-white shadow-lg"
              >
                <div class="flex-auto px-4 py-5">
                  <div
                    class="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-red-400 p-3 text-center text-white shadow-lg"
                  >
                    <i class="fas fa-tablet"></i>
                  </div>
                  <h6 class="text-2xl font-semibold">Introduction aux concepts de BPM </h6>
                  <p class="mt-2 mb-4 text-xl text-gray-600">
                    Il s'agit de comprendre les principaux concepts de BPM, comme la cartographie des processus, l'optimisation des processus et la gestion des performances.
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full px-4 text-center md:w-1/3">
              <div
                class="relative mb-8 flex w-full min-w-0 flex-col break-words rounded-lg bg-white shadow-lg"
              >
                <div class="flex-auto px-4 py-5">
                  <div
                    class="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-blue-400 p-3 text-center text-white shadow-lg"
                  >
                    <i class="fas fa-laptop"></i>
                  </div>
                  <h6 class="text-2xl font-semibold">Analyse des processus,suivi et surveillance</h6>
                  <p class="mt-2 mb-4 text-xl text-gray-600">
                    Il s'agit de comprendre comment identifier les processus clés de l'entreprise, les analyser et les cartographier. Ensuite, il s'agit de comprendre comment surveiller les processus pour s'assurer qu'ils fonctionnent efficacement et de détecter les éventuels problèmes.
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full px-4 pt-6 text-center md:w-1/3 lg:pt-12">
              <div
                class="relative mb-8 flex w-full min-w-0 flex-col break-words rounded-lg bg-white shadow-lg"
              >
                <div class="flex-auto px-4 py-5">
                  <div
                    class="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-red-400 p-3 text-center text-white shadow-lg"
                  >
                    <i class="fas fa-mobile"></i>
                  </div>
                  <h6 class="text-2xl font-semibold">Mise en place d'outils </h6>
                  <p class="mt-2 mb-4 text-xl text-gray-600">
                    Il s'agit de comprendre comment mettre en place des outils pour faciliter la gestion des processus, par exemple un logiciel de BPM.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font relative">
        <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-indigo-500">Contactez-nous</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base">N’hésitez pas à nous contacter ☺️.</p>
    </div>
    <form ref="form" @submit.prevent="sendEmail">
    <div class="lg:w-1/2 md:w-2/3 mx-auto">
      <div class="flex flex-wrap -m-2">
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="name" class="leading-7 text-sm text-gray-600">Nom</label>
            <input type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-full">
          <div class="relative">
            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
        </div>
        <div class="p-2 w-full">
          <button type="submit" class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Envoyez</button>
        </div>
      </div>
      <div class="p-2 w-full"><router-link to="/formation-banques">Notre formation en cours</router-link></div>
    </div>
  </form>
  </div>
</section>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import web from "../assets/images/d.png"
export default {
  name: 'Formation',
  components: { },
  data () {
    return {
      images: { web },
      name: '',
      email: '',
      message: ''

    }
  },
  created: function () {
    document.title = "BforProcess | Leader de l'approche processus"
    document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          name: this.name,
          email: this.email,
          message: this.message
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/sass/variables.scss";

  .bg-primaryBlue{
    background: $devs_orange;
  }
  .web-title {
    color: $devs_orange;
    border-bottom-color: $devs_orange;
    width: max-content;
  }
  @media screen and (max-width: 600px) {
    .web, .web-detail {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .web-title {
      font-size: 1.5rem;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .web, .web-detail {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .web-title {
      font-size: 2.5rem;
    }
  }
</style>
