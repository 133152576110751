/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_point': {
    width: 65.333,
    height: 70.667,
    viewBox: '0 0 49 53',
    data: ''
  }
})
