/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 .667a8.333 8.333 0 110 16.666A8.333 8.333 0 019 .667zm0 1.666a6.667 6.667 0 100 13.334A6.667 6.667 0 009 2.333zm2.744 3.578l-4.41 4.41-1.078-1.077a.833.833 0 10-1.178 1.179l1.666 1.666a.833.833 0 001.179 0l5-5a.833.833 0 10-1.179-1.178z" _fill="#2C5AA0"/>'
  }
})
