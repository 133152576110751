/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'export': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.5 8.125V2.5A1.5 1.5 0 014 1h9a1.5 1.5 0 011.5 1.5v9A1.5 1.5 0 0113 13H8.5m-6-7.5h12M7 1v8.625M1 12.25h5.25M4 10l2.25 2.25L4 14.5" _stroke="#282D34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
