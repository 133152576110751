<template>
  <div
    class="project-details p-4"
    data-aos="fade-up" data-aos-offset="200" data-aos-delay="30" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="true" data-aos-anchor-placement="top-bottom"
  >
  <div class="relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg"
        style="background-position: 50%;" data-mdb-ripple="true" data-mdb-ripple-color="light">
        <img
        :src="project.picture"
        class=""
      >
        <a href="#!">
          <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
            style="background-color: rgba(0, 0, 0, 0.4)">
            <div class="flex justify-start items-end h-full">
              <div class="text-white m-6">
                <h5 class="font-bold text-lg mb-3"> {{ project.name }}: {{ project.description }}</h5>
              </div>
            </div>
          </div>
        </a>
      </div>
    <div
      v-if="showMoreBtn"
      class="btnMore opacity-25 cursor-pointer text-lg"
      @click="gotoProject"
    >
      {{ project.btnMore }}
    </div>
  </div>
</template>

<script>
import rema from '../../../assets/images/rema.png'

export default {
  name: 'work-project',
  props: {
    project: {
      type: Object,
      default: function () {
        return {
          picture: rema,
          name: 'REMA',
          type: 'Mobile & Web',
          description: this.$t('remaDesc'),
          btnMore: this.$t('learnMoreBtn'),
          url: 'https://www.remaapp.com'
        }
      }
    },
    showMoreBtn: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    gotoProject () {
      // this.$router.push(this.project.url)
      window.open(this.project.url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables.scss";
  .type {
    color: $devs_main-color;
    background-color: $ylomi_gray;
  }
</style>
