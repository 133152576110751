<template>
  <div class="mx-4 pt-5" data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
    <div
  class="flex flex-1 w-full flex-col items-center justify-center text-center px-4 py-20 bg-gradient-to-r from-green-50/50 via-teal-50 to-green-50/50 dark:from-gray-800 dark:via-gray-700 dark:to-gray-800">
  <h1
    class="mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-white-300 dark:text-gray-300 sm:text-7xl">
    Devenez un expert en Business Process Management
    <span class="relative whitespace-nowrap text-orange-500 dark:text-orange-300">
      <svg aria-hidden="true" viewBox="0 0 418 42" class="absolute top-2/3 left-0 h-[0.58em] w-full fill-orange-500 dark:fill-orange-300/60" preserveAspectRatio="none">
        <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.780 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.540-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.810 23.239-7.825 27.934-10.149 28.304-14.005 .417-4.348-3.529-6-16.878-7.066Z"></path>
      </svg>
      <span class="relative">avec Bforprocess</span>
    </span>
  </h1>
  <h2 class="mt-3 text-base text-white-300 dark:text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
    Vous êtes passionné par le secteur bancaire et vous cherchez à maîtriser les processus métier pour une performance optimale ? Rejoignez-nous pour une formation immersive de 4 jours en ligne sur Zoom, animée par les experts de BforProcess.
  </h2>
  <!--<a class="bg-orange-600 dark:bg-gray-800 rounded-xl text-white dark:text-gray-300 font-medium px-4 py-3 sm:mt-10 mt-8 hover:bg-orange-500 dark:hover:bg-gray-600 transition"
    href="https://drive.google.com/file/d/1yz-64ixuJ6fd11bympirQPQwadcoiRQ4/view">Obtenir la fiche </a> -->
   <!-- ... -->
</div>
<Form></Form>


<PopupWrapper class="max-w-md mx-auto  lg:justify-center  bg-white shadow-lg rounded-lg overflow-hidden">
      <template #header>
        <div class="popover bg-orange-600 text-white py-2 px-4 rounded hover:bg-gray-800 focus:outline-none focus:shadow-outline" @click="openForm">Obtenir la fiche</div>
      </template>
      <template #content>
      </template>
    </PopupWrapper> 
   
<section class="sm:mt-6 lg:mt-8 mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

<div
    class="my-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex gap-3 lg:flex-justify lg:flex flex-col lg:flex-row">
    <div class="sm:text-center lg:text-left">
        <h1 class="text-4xl tracking-tight font-extrabold text-gray-800 sm:text-5xl md:text-6xl">
            <span class="block text-orange-600 xl:inline">À propos de la formation</span>
        </h1>
        <p
            class="mt-3 text-base leading-7 text-white-300 dark:text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Notre formation <span>"Business Process Management dans le Secteur Bancaire"</span>  est conçue pour répondre aux besoins spécifiques des professionnels évoluant dans le domaine bancaire. BforProcess, nous comprenons les défis uniques auxquels vous êtes confrontés et nous avons développé un programme exhaustif pour vous aider à les surmonter avec succès.
        </p>
        <!-- Button Section -->
        <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a href="https://app.flokzu.com/public/0300bLFORB" class="w-full bg-orange-600 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white  hover:bg-gray-600 md:py-4 md:text-lg md:px-10" to="/contact-formation">Réserver ma place</a>
            </div>
        </div>
        <!-- End of Button Section -->
       
    </div>

    <!--   Image Section     -->
    <div class="lg:inset-y-0 lg:right-0 lg:w-1/2 my-4">
        <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="../assets/homme.jpg" alt="">
    </div>
    <!--   End of Image Section     -->
</div>

</section>
<Form></Form>
    <div class="bg-gray-100">
    <div class="container mx-auto py-8">
        <div class="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
            <div class="col-span-4 sm:col-span-3">
                <div class="bg-white shadow rounded-lg p-6">
                    <div class="flex flex-col items-center">
                        <img src="https://d1yei2z3i6k35z.cloudfront.net/4248991/65c38f414b18b_1691240516233.jpg" class="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0">
                        </img>
                        <h1 class="text-xl font-bold"> Romaric TCHOKPON,</h1>
                        <p class="text-gray-700">Expert BPM</p>
                        <a class="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds LinkedIn" href="https://www.linkedin.com/in/tromaric/"
                            target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-6">
                                <path fill="currentColor"
                                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                </path>
                            </svg>
                        </a>
                        <div class="mt-6 flex flex-wrap gap-4 justify-center">
                            <a href="https://wa.me/message/KLNFJAETBXJMM1" class="bg-orange-600 hover:bg-blue-600  text-white py-2 px-4 rounded">Echanger avec nous</a>
                        </div>
                    </div>
                    <hr class="my-6 border-t border-gray-300">
                    <div class="flex flex-col items-center">
                        <img src="https://media.licdn.com/dms/image/D5603AQGotEynyIEo3g/profile-displayphoto-shrink_400_400/0/1692231228921?e=1713398400&v=beta&t=DphSiS6NpoxS-htf2olAT3XrPzjD-SW_0WwAZrS8-Y8" class="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0">
                        </img>
                        <h1 class="text-xl font-bold"> Abdoulaye DIARRA,</h1>
                        <p class="text-gray-700">Chef de Projet BPM</p>
                        <a class="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds LinkedIn" href="https://www.linkedin.com/in/abdoulaye-diarra-4459a8176/"
                            target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-6">
                                <path fill="currentColor"
                                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                </path>
                            </svg>
                        </a>
                        <div class="mt-6 flex flex-wrap gap-4 justify-center">
                          <a href="https://wa.me/message/KLNFJAETBXJMM1" class="bg-orange-600 hover:bg-blue-600  text-white py-2 px-4 rounded">Echanger avec nous</a>
                        </div>
                    </div>
                    <hr class="my-6 border-t border-gray-300">
                    <div class="flex flex-col items-center">
                      <img src="https://media.licdn.com/dms/image/D4D03AQFosJMhakSezA/profile-displayphoto-shrink_400_400/0/1676494916238?e=1713398400&v=beta&t=eeRS2SKCnhWVlcoY7p8QpRcwUF88lHUPPMTeXfZcpK0" class="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0">
                        </img>
                        <h1 class="text-xl font-bold"> Mahulidji Denis HOUESSOU
                       
</h1>
                        <p class="text-gray-700">Chef de projet RPA</p>
                        <a class="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds LinkedIn" href="   https://www.linkedin.com/in/mahulidji-denis-houessou/"
                            target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-6">
                                <path fill="currentColor"
                                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                </path>
                            </svg>
                        </a>
                        <div class="mt-6 flex flex-wrap gap-4 justify-center">
                          <a href="https://wa.me/message/KLNFJAETBXJMM1" class="bg-orange-600 hover:bg-blue-600  text-white py-2 px-4 rounded">Echanger avec nous</a>
                        </div>
                    </div>
                    <hr class="my-6 border-t border-gray-300">
                    
                    <div class="flex flex-col">
                        <span class="text-gray-700 uppercase font-bold tracking-wider mb-2">Notion à Apprendre</span>
                        <ul>
                            <li class="mb-2">BPM</li> <br>
                            <li class="mb-2">BPMN</li> <br>
                            <li class="mb-2">Camunda</li><br>
                            <li class="mb-2">RPA</li> <br>
                            <li class="mb-2">Gestion des Processus</li>
                        </ul>
                    </div>
                    <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
    <div
      class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
      <div class="mx-auto max-w-xs px-8">
        <p class="text-base font-semibold text-gray-600">Prix</p>
        <p class="mt-6 mb-12 flex items-baseline justify-center gap-x-2">
          <span class="text-5xl font-bold tracking-tight text-gray-900 "><span class=" px-2">100.000</span></span>
          <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">Fcfa</span>
        </p>
        <a href="https://app.flokzu.com/public/0300bLFORB" class="bg-blue-600 text-white rounded-sm py-2 w-full block my-6" >Inscrivez vous maintenant →</a>
        </p>
      </div>
      <img src="../assets/images/paiement.png" alt="">
    </div>
  </div>
                </div>
            </div>
            <div class="col-span-4 sm:col-span-9">
                <div class="bg-white shadow rounded-lg p-6">
                    <h2 class="text-3xl text-orange-600  font-bold mb-4">À propos du formateur</h2>
                    <p class="text-xl leading-7 text-white-300 dark:text-gray-300 justify-end">Je suis Romaric TCHOKPON, expert BPM. Avec plus de 10 années d'expérience dans la gestion et l'amélioration des processus métier, j'ai consacré ma carrière à aider les organisations à atteindre des performances optimales et à réussir leur transformation numérique grâce à l'approche BPM et aux outils associés.


                    </p>



                    <h2 class="text-xl text-orange-600  font-bold mt-6 mb-4">Qu'allez-vous apprendre durant cette formation ?</h2>
                    <h3 class="text-xl  mt-6 mb-4 leading-7 justify-end">À la fin de cette formation, vous aurez acquis une vue holistique du Management des Processus Métier appliqué spécifiquement au secteur bancaire. Vous serez armé d'une compréhension approfondie des meilleures pratiques en matière de BPM et de leur application dans le contexte financier.</h3>
                    <div class="mb-6">
                        <div class="flex justify-between flex-wrap gap-2 w-full">
                            <span class="ext-primaryBlue ">1-Les Fondamentaux du Business Process Management (BPM)</span>
                            <p>
                         
                            </p>
                        </div>
                   
                    </div>
                    <div class="mb-6">
                        <div class="flex justify-between flex-wrap gap-2 w-full">
                            <span class="ext-primaryBlue ">
                              2-Identification et Analyse des Processus Métier dans le Secteur Bancaire</span>
                            <p>
                            
                            </p>
                        </div>
                     
                    </div>
                    <div class="mb-6">
                        <div class="flex justify-between flex-wrap gap-2 w-full">
                            <span class="ext-primaryBlue ">3-Techniques d'Optimisation des Processus pour Accroître l'Efficacité Opérationnelle</span>
                            <p>
                                
                            </p>
                        </div>
                   
                    </div>
                    <div class="mb-6">
                        <div class="flex justify-between flex-wrap gap-2 w-full">
                            <span class="ext-primaryBlue ">4-Gestion du Changement et Mise en Œuvre de Solutions BPM</span>
                            <p>
                               
                            </p>
                        </div>
                      
                    </div>
                    <div class="mb-6">
                        <div class="flex justify-between flex-wrap gap-2 w-full">
                            <span class="ext-primaryBlue ">5-Études de Cas Pratiques et Exemples Concrets du Secteur Bancaire</span>
                           
                        </div>
                      
                    </div>
                    <h1 class="text-orange-600 ">Planning de la formation</h1>
                    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-blue-500 text-white relative z-10 title-font font-medium text-sm">1</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-blue-100 text-blue-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-orange-600 mb-1 text-xl">JOUR 1 : 04 Mars 2024</h2>
            <p class="">
              <h2 class="font-medium title-font text-white-300 dark:text-gray-300 py-2 mb-1 text-xl">☞Introduction au BPM et son importance dans le secteur financier et bancaire :</h2>  Vous découvrirez les concepts fondamentaux du Business Process Management (BPM) et son application spécifique dans le secteur bancaire. <br>
              <h2 class="font-medium title-font text-white-300 dark:text-gray-300 py-2 mb-1 text-xl">☞Cartographie des processus métier bancaires :</h2> Nous commencerons par explorer le mindset nécessaire pour une cartographie réussie des processus. Ensuite, nous présenterons les outils pratiques indispensables à votre succès dans cette démarche. <br>
              <h2 class="font-medium title-font text-white-300 dark:text-gray-300 py-2 mb-1 text-xl">☞Identification des opportunités d'amélioration des processus :</h2> Vous apprendrez à détecter les lacunes dans les processus bancaires actuels et à mettre en œuvre des méthodologies d'optimisation pour les améliorer.
            </p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-blue-500 text-white relative z-10 title-font font-medium text-sm">2</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-blue-100 text-blue-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-orange-600 mb-1 text-xl">JOUR 2 : 05 Mars 2024
</h2>
         
              <h2 class="font-medium title-font text-white-300 leading-7 dark:text-gray-300 py-2 mb-1 text-xl">☞Techniques avancées d'analyse des processus :</h2>  Approfondissez vos compétences en analyse des processus en explorant des méthodes avancées telles que l'analyse de la valeur ajoutée, l'analyse des goulets d'étranglement et l'analyse des flux de travail. <br>
              <h2 class="font-medium title-font text-white-300 leading-7 dark:text-gray-300 py-2  mb-1 text-xl">☞Méthodes d'optimisation des processus pour accroître l'efficacité opérationnelle :</h2> Découvrez des techniques éprouvées pour optimiser les processus bancaires, y compris l'identification et l'élimination des gaspillages, la simplification des workflows et l'optimisation des ressources. <br>
              <h2 class="font-medium title-font text-white-300 leading-7 dark:text-gray-300 py-2 mb-1 text-xl">☞Études de cas pratiques de l'application du BPM dans les institutions financières :</h2> Explorez des exemples concrets de l'application l'application du BPM dans le secteur bancaire, en examinant comment des organisations ont amélioré leurs processus pour atteindre des niveaux d'efficacité et de rentabilité accrus.</p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-blue-500 text-white relative z-10 title-font font-medium text-sm">3</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-blue-100 text-blue-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <circle cx="12" cy="5" r="3"></circle>
            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-orange-600 mb-1 text-xl">JOUR 3: 06 Mars 2024 </h2>


          <p>
            <h2 class="font-medium title-font leading-7 text-white-300 dark:text-gray-300 py-2 mb-1 text-xl">☞Intégration des outils technologiques dans la gestion des processus bancaires : </h2> Explorez comment les outils technologiques modernes, tels que les systèmes de gestion de flux de travail et les plates-formes d'automatisation, peuvent être intégrés dans la gestion quotidienne des processus bancaires pour améliorer l'efficacité et la précision. <br>
            <h2 class="font-medium title-fontleading-7 text-white-300 dark:text-gray-300 py-2 mb-1 text-xl">☞ Introduction à l'automatisation des processus métier (RPA et Low-code) :</h2>  Plongez dans le monde de l'automatisation des processus avec un aperçu complet de la technologie RPA, comprenant ses applications, ses avantages et ses meilleures pratiques dans le contexte bancaire. <br>
            <h2 class="font-medium title-font leading-7 text-white-300 dark:text-gray-300 py-2 mb-1 text-xl">☞Cas d'utilisation de la transformation digitale dans le secteur financier : </h2> Analysez des cas concrets de transformation digitale réussie dans le secteur financier, en examinant comment les banques ont adopté de nouvelles technologies pour répondre aux besoins changeants des clients et rester compétitives sur le marché.


          </p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-blue-500 text-white relative z-10 title-font font-medium text-sm">4</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-blue-100 text-blue-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <circle cx="12" cy="5" r="3"></circle>
            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-orange-600 mb-1 text-xl">JOUR 4: 07 Mars 2024

</h2>
      
          <p>
           
            <h2 class="font-medium title-font leading-7  dark:text-gray-300 py-2 mb-1 text-xl">☞Stratégies de mise en œuvre du BPM  :</h2> Apprenez les meilleures pratiques pour mettre en œuvre avec succès des initiatives de BPM dans les institutions bancaires, y compris la planification stratégique, la gestion des ressources et l'engagement des parties prenantes. <br>
            <h2 class="font-medium title-font leading-7 dark:text-gray-300 py-2 mb-1 text-xl">☞Gestion du changement :</h2>  Découvrez comment gérer efficacement le changement organisationnel lors de la mise en œuvre de nouvelles pratiques de BPM, en mobilisant et en motivant les équipes pour garantir une transition fluide et réussie. <br>
            <h2 class="font-medium title-font leading-7  dark:text-gray-300 py-2 mb-1 text-xl">☞Planification et développement d'une feuille de route des prochaines étapes et développement d'une feuille de route :</h2> Élaborez une feuille de route stratégique pour l'amélioration continue des processus bancaires, en identifiant les objectifs à long terme, les jalons clés et les initiatives prioritaires pour maintenir la compétitivité et l'efficacité opérationnelle.tion digitale dans le secteur financier : Analysez des cas concrets de transformation digitale réussie dans le secteur financier, en examinant comment les banques ont adopté de nouvelles technologies pour répondre aux besoins changeants des clients et rester compétitives sur le marché.


          </p>
        </div>
      </div>
    </div>
 
  </div>
</section>

                </div>
            </div>
            
        </div>
    </div>
</div>
<div class="mx-auto max-w-7xl px-6 lg:px-8">

<div class="mx-auto mt-8 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-12 lg:mx-0 lg:flex lg:max-w-none">
  <div class="p-8 sm:p-10 lg:flex-auto">
    <h1 class="text-3xl font-bold tracking-tight text-gray-900">Faites le Meilleur Investissement de l'Année</h1>
    <p class="mt-6 text-base text-xl leading-7 leading-7 text-gray-600">Votre opportunité d'investissement le plus judicieux de l'année est là ! Plongez dans notre formation spécialisée en Business Process Management (BPM) conçue spécifiquement pour le secteur bancaire. En acquérant une expertise approfondie dans la gestion des processus métier, vous vous positionnez comme un acteur clé dans l'optimisation des opérations bancaires. Notre programme vous offre non seulement une compréhension complète des concepts et des meilleures pratiques du BPM, mais également des études de cas spécifiques au secteur financier, des opportunités de réseautage avec des professionnels de l'industrie, et des outils pratiques pour mettre en œuvre des améliorations stratégiques au sein de votre organisation.</p>
    <div class="mt-10 flex items-center gap-x-4">
      <h4 class="flex-none text-sm font-semibold leading-6 text-blue-600">Ce qui est inclus</h4>
      <div class="h-px flex-auto bg-gray-100"></div>
    </div>
    <ul class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
      <li class="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify"
          viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet" version="1.0"
          id="IconChangeColor">
          <defs>
            <clipPath id="id1">
              <path
                d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                clip-rule="nonzero" id="mainIconPathAttribute" fill="green"></path>
            </clipPath>
          </defs>
          <g clip-path="url(#id1)">
            <path fill="green"
              d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
          </g>
        </svg> <span class="font-bold mx-1">Accès aux réseaux de professionnels du BPM.</span></li>
      <li class="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify"
          viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet" version="1.0"
          id="IconChangeColor">
          <defs>
            <clipPath id="id1">
              <path
                d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                clip-rule="nonzero" id="mainIconPathAttribute" fill="green"></path>
            </clipPath>
          </defs>
          <g clip-path="url(#id1)">
            <path fill="green"
              d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
          </g>
        </svg> <span class="font-bold mx-1">Réduction de 10% sur le module de préparation de la certification OCEB.</span></li>
      <li class="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify"
          viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet" version="1.0"
          id="IconChangeColor">
          <defs>
            <clipPath id="id1">
              <path
                d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                clip-rule="nonzero" id="mainIconPathAttribute" fill="green"></path>
            </clipPath>
          </defs>
          <g clip-path="url(#id1)">
            <path fill="green"
              d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
          </g>
        </svg> <span class="font-bold mx-1"></span><span>Accompagnement personnalisé d'une heure</span></li>
      <li class="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify"
          viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet" version="1.0"
          id="IconChangeColor">
          <defs>
            <clipPath id="id1">
              <path
                d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                clip-rule="nonzero" id="mainIconPathAttribute" fill="green"></path>
            </clipPath>
          </defs>
          <g clip-path="url(#id1)">
            <path fill="green"
              d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
          </g>
        </svg> <span class="font-bold mx-1">Accès aux supports de cours complets.</span></li>
      <li class="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify"
          viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet" version="1.0"
          id="IconChangeColor">
          <defs>
            <clipPath id="id1">
              <path
                d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                clip-rule="nonzero" id="mainIconPathAttribute" fill="green"></path>
            </clipPath>
          </defs>
          <g clip-path="url(#id1)">
            <path fill="green"
              d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
          </g>
        </svg> <span class="font-bold mx-1">
Accès aux outils de gestion de processus métier (BPM).</span></li>
<li class="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify"
          viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet" version="1.0"
          id="IconChangeColor">
          <defs>
            <clipPath id="id1">
              <path
                d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                clip-rule="nonzero" id="mainIconPathAttribute" fill="green"></path>
            </clipPath>
          </defs>
          <g clip-path="url(#id1)">
            <path fill="green"
              d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
          </g>
        </svg> <span class="font-bold mx-1">
Attestion de formation.</span></li>
    </ul>
  </div>
  <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
    <div
      class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
      <div class="mx-auto max-w-xs px-8">
        <p class="text-base font-semibold text-gray-600">Prix</p>
        <p class="mt-6 mb-12 flex items-baseline justify-center gap-x-2">
          <span class="text-5xl font-bold tracking-tight text-gray-900 "><span class=" px-2">100.000</span></span>
          <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">Fcfa</span>
        </p><a href="https://app.flokzu.com/public/0300bLFORB" class="bg-blue-600 text-white rounded-sm py-2 w-full block my-6" >Inscrivez vous maintenant →</a>
        </p>
        <img src="../assets/images/paiement.png" alt="">
      </div>
    </div>
  </div>
</div>

</div>
  


<ul class="flex primaryBlue flex-col md:grid grid-cols-3 gap-5 text-redis-neutral-800  mx-auto p-10 mt-10">
    <li
        class="w-full text-sm font-semibold text-slate-900 p-6 bg-white border border-slate-900/10 bg-clip-padding shadow-md shadow-slate-900/5 rounded-lg flex flex-col justify-center">
        <span class="mb-1 text-orange-600 font-display text-5xl">95%</span>
        98% de nos participants ont constaté un impact positif sur leurs compétences en modélisation.    </li>
    <li
        class="w-full text-sm font-semibold text-slate-900 p-6 bg-white border border-slate-900/10 bg-clip-padding shadow-md shadow-slate-900/5 rounded-lg flex flex-col justify-center">
        <span class="mb-1 text-orange-600 font-display text-5xl">81%</span>
        Ont été promus à l'issue de cette formation    </li>
    <li
        class="w-full text-sm font-semibold text-slate-900 p-6 bg-white border border-slate-900/10 bg-clip-padding shadow-md shadow-slate-900/5 rounded-lg flex flex-col justify-center">
        <span class="mb-1 text-orange-600 font-display text-5xl">88%</span>
        Travaillent actuellement dans des postes de direction
    </li>
</ul>

<!--
<Counter :year="2024" :month="4" :date="25" :hour="0" :minute="59" :second="59" :millisecond="31"/> -->
<div class="max-w-screen-xl mx-auto py-8 px-4 lg:py-16 lg:px-6">
    <div class="text-center mb-10">
        <h2 class="text-4xl tracking-tight font-bold text-primary-800 py-3">À qui s'adresse cette formation ?</h2>
        <h3>Cette formation s'adresse aux professionnels du secteur bancaire de tous niveaux d'expérience, y compris les responsables opérationnels, les analystes de processus, les chefs de projet, les responsables de la qualité et toute personne intéressée par l'amélioration des processus dans le domaine financier.

</h3>
    </div>

    <div class="flex flex-col md:flex-row">
        <!-- can help image -->
        <div class="mr-0 md:mr-8 mb-6 md:mb-0">
          <iframe width="340" height="192"  src="https://www.youtube.com/embed/du6KEQNXL74" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            
        </div>
        <!-- end can help image -->

        <div class="flex-1 flex flex-col sm:flex-row flex-wrap -mb-4 -mx-2">
            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                    <h3 class="text-2xl font-bold text-md mb-6">Directeur des opérations bancaires</h3>
                </div>
            </div>
            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                    <h3 class="text-2xl font-bold text-md mb-6">Analyste de processus métier dans le secteur bancaire</h3>
                </div>
            </div>

            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                    <h3 class="text-2xl font-bold text-md mb-6">Chef de projet en transformation digitale dans les services financiers</h3>
                </div>
            </div>

            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                    <h3 class="text-2xl py-4 font-bold text-md mb-6">Responsable qualité et amélioration continue dans la banque</h3>
                </div>
            </div>


        </div>
    </div>
</div>
<!-- Testimonials section -->
<section class="bg-orange-600 text-white justify-center py-16">
    <div class="max-w-7xl mx-auto justify-center px-4 sm:px-6 lg:px-16">
        <h2 class="text-4xl font-bold mb-8">"Regardez ce que nos clients disent !"</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div class="bg-white shadow rounded-lg p-8">
              <iframe width="500" height="392" src="https://www.youtube.com/embed/PVXdKqzR-D0?si=D5FHimj-OU95H_Cx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                <p class="text-gray-700 mb-4">"Le Directeur Général de GEOMIN SA partage son expérience avec Bforprocess. Nous sommes fiers de contribuer à la transformation organisationnelle et digitale des entreprises africaines grâce au BPM."</p>
                <p class="text-gray-700 font-medium">- Mamadi, CEO Geomin</p>
            </div>
            <div class="bg-white shadow rounded-lg p-8">
              <iframe width="500" height="392" src="https://www.youtube.com/embed/24MPTMmgt5I" title="LA DIGITALISATION DES PROCESSUS  LEVIER DE PERFORMANCE DES ÉCOLES" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                <p class="text-gray-700 mb-4">"Un des leaders multinationaux de l'enseignement supérieur, ESMT Dakar, partage son expérience avec Bforprocess. Écoutez ce que Mohamed DIALLO, chef de service de contrôle de gestion, témoigne à propos de Bforprocess."</p>
                <p class="text-gray-700 font-medium">- Mohamed DIALLO, chef de service de contrôle de gestion ESMT</p>
            </div>
          
        </div>
    </div>
</section>
<div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
            <div class="rounded-md shadow">
                <a href="https://app.flokzu.com/public/0300bLFORB"
                    class="w-full bg-orange-600 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white  hover:bg-gray-600 md:py-4 md:text-lg md:px-10">
                    Réserver ma place
                </a>
            </div>
        </div>  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import db from "../firebase/firebaseInit";
import logo from "@/assets/icons/ic_logo.svg";
import mailIcon from "@/assets/icons/mail.svg";
import clockIcon from "@/assets/icons/clock.svg";
import pdf from 'vue-pdf'
import DownloadPdfButton from '../components/Pdf.vue';
import PopupWrapper from '../components/PopupWrapper.vue';
import Counter from '../components/Counter.vue'
import Form from '../components/form/index.vue'
import emailjs from 'emailjs-com';





export default {
  name: "Rodolphe",
  components: {pdf, DownloadPdfButton,Form, Counter, PopupWrapper},
  props: [
        "year",
        "month",
        "date",
        "hour",
        "minute",
        "second",
        "millisecond",
      ], 
  data() {
    return {
      emailAddress:'',
      dialog: null,
      pdfUrl: '../../public/formation.pdf',
      pdfFileName: 'brochure-formation.pdf',
      icons: { logo, mailIcon, clockIcon },
      displayDays: 0,
        displayMinutes: 0,
        displayHours: 0,
        displaySeconds: 0,
      week_days: [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ],
      name: '',
      email: '',
      message: '',
      prename:'',
      service: '',
      service1: '',
      message1: '',
      phone: '',
      events: [
        "Réunion générale extraordinaire",
        "Entretient poste developpeur web",
        "Revue de troupe",
        "Depart de congé : Moris Dahgan",
      ],
      current_day: 0,
    };
  },
  computed: {
   _seconds: () => 1000,
   _minutes () {
    return this._seconds * 60
    },
    _hours () {
    return this._minutes * 60
    },
    _days () {
    return this._hours * 24
    },
    end() {
    return new Date (
      this.year,
      this.month,
      this.date,
      this.hour,
      this.minute,
      this.second,
      this.millisecond
    ) 
    },
  },
  mounted(){
    this.showRemaining();
    this.openForm();

    },
  methods: {
    submit: function(){
    db.collection("EmailAddresses").add({
      emailAddress: this.emailAddress,
    })
    },
    openDialog() {
      if (this.dialog) {
        this.dialog.show()
      }
    },

    assignDialogRef(dialog) {
      this.dialog = dialog
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          name: this.name,
          prename: this.prename,
          email: this.email,
          phone: this.phone,
          service: this.service,
          service1: this.service1,
          message: this.message,
          message1: this.message1
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.prename = ''
      this.service = ''
      this.service1 = ''
      this.phone = ''
      this.message1 = ''
      this.email = ''
      this.message = ''
    },
    openForm() {
      document.getElementById("myForm").style.display = "block";
    },
    formatNum: num => (num < 10 ? "0" + num : num),
    showRemaining() {

      const timer = setInterval(() =>{
        const now = new Date();
        const distance = this.end.getTime() - now.getTime()
        if(distance < 0) {
          clearInterval(timer);
          return;
    }
    const days = Math.floor(distance / this._days);
    const hours = Math.floor((distance % this/this._days) / this._hours);
    const minutes = Math.floor((distance % this/this._hours) / this._minutes);
    const seconds = Math.floor((distance % this/this._minutes) / this._seconds);
    this.displayMinutes = minutes < 10 ? "0" + minutes : minutes;
    this.displaySeconds = seconds < 10 ? "0" + seconds : seconds;
    this.displayHours = hours < 10 ? "0" + hours : hours;
    this.displayDays = days < 10 ? "0" + days : hours;

    },1000 )
    },
  },

};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";



.primaryBlue{
  background-color: #1D71B8;
}
.zaza{
  color: #1D71B8;
}

.popover {
  font-size: 14px;
  width: 180px;
  cursor: pointer;
  font-weight: 600;
  align-items:center ;
  justify-content: center;
}

.popover:hover {
  background: #2b2b2b;
  color: #fff;
}

.popover-content {
  position: absolute;
  background: #fff;
  border-radius: 6px;
  margin-top: 4px;
  padding: 16px;
  color: #333;
  min-width: 200px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .header-mobile {
    display: block;
    z-index: 9999;
    background-color: white;
  }
  iframe {
    width: 340px;
    height: 192px;
 
  }
}
</style>
