/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_notification': {
    width: 28,
    height: 31,
    viewBox: '0 0 28 31',
    data: '<path pid="0" d="M26.59 22.023c-1.816-2.148-3.097-3.242-3.097-9.165 0-5.424-2.865-7.357-5.222-8.296-.314-.124-.608-.41-.704-.72-.413-1.362-1.573-2.56-3.114-2.56-1.54 0-2.7 1.199-3.11 2.56-.096.315-.39.596-.704.72-2.36.94-5.222 2.866-5.222 8.296-.003 5.923-1.285 7.017-3.1 9.165-.752.89-.094 2.227 1.222 2.227h21.836c1.308 0 1.963-1.34 1.215-2.227zM18.978 24.25v1.094c0 1.16-.477 2.273-1.326 3.093-.848.82-1.999 1.282-3.199 1.282-1.2 0-2.35-.461-3.199-1.282a4.303 4.303 0 01-1.325-3.093V24.25" _stroke="#909090" stroke-width="2.188" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
