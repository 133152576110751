/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'aero': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.33 8.767a8.334 8.334 0 10-4.868 7.815.833.833 0 10-.693-1.515A6.667 6.667 0 1115.667 9v.833l-.004.141c-.056.88-.625 1.526-1.246 1.526-.653 0-1.25-.716-1.25-1.667V9l-.004-.18a4.167 4.167 0 10-1.093 2.997c.525.814 1.37 1.35 2.347 1.35 1.648 0 2.917-1.523 2.917-3.334V9l-.003-.233zM6.5 9a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z" _fill="#D70001"/>'
  }
})
