/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_email-link': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<circle pid="0" cx="44" cy="44" r="44" _fill="#F3F5FA"/><path pid="1" d="M45.308 31.308A1.308 1.308 0 0146.615 30h13.077a1.308 1.308 0 010 2.615H46.615a1.308 1.308 0 01-1.307-1.307zm0 5.23a1.308 1.308 0 011.307-1.307h13.077a1.308 1.308 0 110 2.615H46.615a1.308 1.308 0 01-1.307-1.307zm0 5.231a1.308 1.308 0 011.307-1.307h13.077a1.308 1.308 0 010 2.615H46.615a1.308 1.308 0 01-1.307-1.308zM32.23 32.615h10.684c.172.487.439.931.776 1.308-.34.377-.601.821-.776 1.308H32.23a2.615 2.615 0 00-2.616 2.615v1.936l13.077 7.04 2.556-1.376c.426.157.886.246 1.367.246h3.693l-6.996 3.767a1.309 1.309 0 01-1.24 0l-12.457-6.706v10.785a2.615 2.615 0 002.616 2.616h20.923a2.615 2.615 0 002.615-2.616v-7.846h2.616v7.846a5.231 5.231 0 01-5.231 5.231H32.23A5.23 5.23 0 0127 53.54V37.846a5.23 5.23 0 015.23-5.23z" _fill="#2C5AA0"/>'
  }
})
