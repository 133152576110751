var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-page"},[_c('div',{staticClass:"max-w-md mx-auto mt-10 bg-white shadow-lg rounded-lg overflow-hidden"},[_c('div',{staticClass:"text-2xl py-4 px-6 bg-orange-600 text-white text-center font-bold uppercase"},[_vm._v(" Formulaire d'inscription ")]),_c('form',{ref:"form",staticClass:"py-4 px-6",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 font-bold mb-2",attrs:{"for":"name"}},[_vm._v(" Nom ")]),_c('input',{staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"type":"text","id":"name","name":"name","placeholder":"Entrer votre nom"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 font-bold mb-2",attrs:{"for":"name"}},[_vm._v(" Prénom ")]),_c('input',{staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"id":"prename","name":"prename","type":"text","placeholder":"Entrer votre  prénom"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 font-bold mb-2",attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('input',{staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"id":"email","name":"email","type":"email","placeholder":"Enter your email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 font-bold mb-2",attrs:{"for":"phone"}},[_vm._v(" Numéro de téléphone ")]),_c('input',{staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"id":"phone","name":"phone","type":"tel","placeholder":"Enter your phone number"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 font-bold mb-2",attrs:{"for":"service"}},[_vm._v(" Secteur ")]),_c('select',{staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"id":"service","name":"service"}},[_c('option',{attrs:{"value":""}},[_vm._v("Selectionner votre secteur")]),_c('option',{attrs:{"value":"haircut"}},[_vm._v("Banque")]),_c('option',{attrs:{"value":"coloring"}},[_vm._v("Assurance")]),_c('option',{attrs:{"value":"styling"}},[_vm._v("Micro Finance ")]),_c('option',{attrs:{"value":"facial"}},[_vm._v("Facial")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 font-bold mb-2",attrs:{"for":"service1"}},[_vm._v(" Années d'Expérience dans le Secteur Bancaire (Liste déroulante) : ")]),_c('select',{staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"id":"service1","name":"service1"}},[_c('option',{attrs:{"value":""}},[_vm._v("Selectionner votre nombre d'années")]),_c('option',{attrs:{"value":"haircut"}},[_vm._v("20 ans")]),_c('option',{attrs:{"value":"coloring"}},[_vm._v("10 ans")]),_c('option',{attrs:{"value":"styling"}},[_vm._v("5 ans ")]),_c('option',{attrs:{"value":"facial"}},[_vm._v("Facial")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 font-bold mb-2",attrs:{"for":"message"}},[_vm._v(" Pourquoi souhaitez-vous suivre cette formation en BPM ? (200 mots max) : ")]),_c('textarea',{staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"id":"message","rows":"4","placeholder":"Enter any additional information"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 font-bold mb-2",attrs:{"for":"message1"}},[_vm._v(" Quels sont vos objectifs professionnels liés à cette formation ? (150 mots max) : ")]),_c('textarea',{staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"id":"message1","name":"message1","rows":"4","placeholder":"Enter any additional information"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center mb-4"},[_c('button',{staticClass:"bg-orange-600 text-white py-2 px-4 rounded hover:bg-gray-800 focus:outline-none focus:shadow-outline",attrs:{"type":"submit"}},[_vm._v(" Soumettre ")])])
}]

export { render, staticRenderFns }