/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_twitter_share': {
    width: 35,
    height: 29,
    viewBox: '0 0 35 29',
    data: '<path pid="0" d="M34.145 4.179a13.688 13.688 0 01-3.93 1.056 6.809 6.809 0 003.001-3.762 13.474 13.474 0 01-4.33 1.649A6.808 6.808 0 0023.898.965a6.823 6.823 0 00-6.824 6.828c0 .528.064 1.057.168 1.565A19.396 19.396 0 013.169 2.213 6.777 6.777 0 002.24 5.66a6.83 6.83 0 003.042 5.687 6.883 6.883 0 01-3.086-.868v.084a6.826 6.826 0 005.472 6.7c-.587.153-1.191.23-1.797.232a9.07 9.07 0 01-1.29-.104 6.837 6.837 0 006.38 4.735 13.679 13.679 0 01-8.473 2.918c-.572 0-1.1-.02-1.649-.084a19.327 19.327 0 0010.483 3.062c12.552 0 19.42-10.399 19.42-19.424 0-.297 0-.593-.02-.89a14.7 14.7 0 003.423-3.53z" _fill="#4F4F4F"/>'
  }
})
