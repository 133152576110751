/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_back-login': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.153 2.29l-.058-.07a.666.666 0 00-.924-.063l-.066.063-4.9 5.25-.029.033-.039.05-.05.084-.037.085-.025.079-.02.11L1 8l.002.056.012.095.02.083.032.083.036.074.052.078.051.061 4.9 5.25a.667.667 0 00.99 0 .79.79 0 00.058-.99l-.058-.07-3.704-3.97H14.3c.387 0 .7-.336.7-.75s-.313-.75-.7-.75H3.39l3.705-3.97a.79.79 0 00.058-.99l-.058-.07.058.07z" _fill="#B5BAC3"/>'
  }
})
