/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_twitter copy': {
    width: 41,
    height: 41,
    viewBox: '0 0 41 41',
    data: '<circle pid="0" opacity=".4" cx="20.71" cy="20.524" r="19.203" _stroke="#E0E0E0" stroke-width="1.422"/><path pid="1" d="M29.028 15.476a5.757 5.757 0 01-1.653.444 2.863 2.863 0 001.262-1.582 5.666 5.666 0 01-1.821.693 2.862 2.862 0 00-2.098-.907 2.87 2.87 0 00-2.87 2.872c0 .222.027.444.07.658A8.157 8.157 0 0116 14.649a2.85 2.85 0 00-.39 1.45c0 .996.506 1.875 1.279 2.392a2.896 2.896 0 01-1.298-.366v.036c0 1.395.987 2.552 2.301 2.818a3.04 3.04 0 01-.755.097c-.187 0-.364-.018-.543-.043a2.876 2.876 0 002.684 1.991 5.753 5.753 0 01-3.564 1.227c-.24 0-.463-.008-.694-.035a8.129 8.129 0 004.41 1.288c5.278 0 8.167-4.374 8.167-8.17 0-.124 0-.249-.009-.373a6.185 6.185 0 001.44-1.485z" _fill="#000"/>'
  }
})
