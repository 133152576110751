/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye-close': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.227 4.174l3.728 3.728c.27-.261.639-.422 1.045-.422.828 0 1.5.669 1.5 1.493 0 .407-.163.776-.428 1.045l3.701 3.702a.75.75 0 11-1.06 1.06l-.676-.674c-.963.558-1.976.84-3.037.84-2.69 0-5.064-1.813-7.13-5.327l-.158-.273-.212-.373.212-.372c.631-1.108 1.291-2.056 1.981-2.84l-.527-.527a.75.75 0 011.061-1.06zm-.818 4.503l-.18.296.156.258C5.156 12.104 7.031 13.454 9 13.454c.655 0 1.298-.15 1.931-.453l-2.834-2.835a1.504 1.504 0 01-.299-.299L4.753 6.82A14.636 14.636 0 003.41 8.678zM9 3c2.69 0 5.064 1.814 7.13 5.327l.158.274.212.372-.367.64c-.576.981-1.177 1.83-1.802 2.544l-1.059-1.059a15.225 15.225 0 001.5-2.124c-1.818-3.05-3.745-4.48-5.772-4.48-.642 0-1.274.143-1.896.436L5.996 3.822C6.95 3.276 7.95 3 9 3z" _fill="#949BA7"/>'
  }
})
