/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blue-eye': {
    width: 24,
    height: 23,
    viewBox: '0 0 24 23',
    data: '<path pid="0" d="M20.871 10.505a1.623 1.623 0 010 1.99c-1.43 1.868-4.863 5.713-8.871 5.713s-7.44-3.845-8.871-5.713a1.622 1.622 0 010-1.99c1.43-1.868 4.863-5.713 8.87-5.713 4.009 0 7.441 3.845 8.872 5.713v0z" _stroke="#2C5AA0" stroke-width="1.917" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M12 14.375a2.875 2.875 0 100-5.75 2.875 2.875 0 000 5.75z" _stroke="#2C5AA0" stroke-width="1.917" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
