/* eslint-disable */
require('./aero')
require('./arrow-left')
require('./arrow-right-light')
require('./arrow-right')
require('./bag')
require('./bars-regular')
require('./blue-eye')
require('./calendar')
require('./camera')
require('./check-circle')
require('./chevron')
require('./clock')
require('./down-white')
require('./down')
require('./edit-regular')
require('./envelope-regular')
require('./export')
require('./eye-close')
require('./eye-open')
require('./file')
require('./finance')
require('./green-mail')
require('./ic_back-login')
require('./ic_download')
require('./ic_email-link')
require('./ic_facebook copy')
require('./ic_facebook_share')
require('./ic_facebook-land')
require('./ic_facebook')
require('./ic_fleche')
require('./ic_insta_share')
require('./ic_instagram-land')
require('./ic_instagram')
require('./ic_linkedin-land')
require('./ic_linkedin')
require('./ic_logo 2')
require('./ic_logo copy')
require('./ic_logo_dark')
require('./ic_logo')
require('./ic_logo1')
require('./ic_more')
require('./ic_notification')
require('./ic_pin')
require('./ic_plus')
require('./ic_point')
require('./ic_profile1')
require('./ic_profile2')
require('./ic_profile3')
require('./ic_profile4')
require('./ic_reseau-land')
require('./ic_shape1')
require('./ic_shape2')
require('./ic_shape3')
require('./ic_shape4')
require('./ic_share')
require('./ic_success-recovery')
require('./ic_twitter copy')
require('./ic_twitter_share')
require('./ic_twitter')
require('./ic_vector1')
require('./ic_vector2')
require('./ic_vector3')
require('./instagram-brands')
require('./linkedin-brands')
require('./location')
require('./lock-alt-solid')
require('./logo 2')
require('./logo')
require('./mail')
require('./money')
require('./phone')
require('./planning')
require('./question')
require('./rh')
require('./right')
require('./search')
require('./seting')
require('./sign-out-alt-regular')
require('./star')
require('./times-circle-light')
require('./trash-regular')
require('./twitter-brands')
require('./user-alt-light')
require('./user-crown-light')
require('./user')
require('./wallet')
require('./youtube-brands')
