/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question': {
    width: 14,
    height: 20,
    viewBox: '0 0 14 20',
    data: '<path pid="0" d="M6.218 13.342c0-.665.11-1.245.333-1.74.221-.495.495-.94.819-1.332.341-.392.708-.75 1.1-1.075.393-.341.76-.674 1.101-.998.342-.342.615-.7.82-1.075.221-.376.332-.811.332-1.306 0-.82-.324-1.485-.972-1.997-.649-.512-1.545-.768-2.688-.768-1.024 0-1.912.17-2.663.512-.75.342-1.39.845-1.92 1.51L.611 3.744c.717-.905 1.63-1.605 2.74-2.1C4.46 1.131 5.757.875 7.24.875c1.23 0 2.296.188 3.2.563.905.359 1.605.88 2.1 1.562.512.666.768 1.476.768 2.432 0 .717-.111 1.331-.333 1.843a5.206 5.206 0 01-.845 1.357c-.341.393-.708.76-1.1 1.1-.376.342-.734.684-1.076 1.025a4.865 4.865 0 00-.844 1.126c-.222.41-.333.896-.333 1.46h-2.56zm1.305 5.812c-.477 0-.879-.163-1.203-.487a1.619 1.619 0 01-.46-1.152c0-.478.153-.87.46-1.177.324-.308.726-.461 1.203-.461.478 0 .87.153 1.178.46.307.308.46.7.46 1.178 0 .444-.153.828-.46 1.152-.307.324-.7.487-1.178.487z" _fill="#fff"/>'
  }
})
