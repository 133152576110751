/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wallet': {
    width: 24,
    height: 20,
    viewBox: '0 0 24 20',
    data: '<path pid="0" d="M21.333 3H12L9.667.667h-7A2.34 2.34 0 00.333 3v14a2.34 2.34 0 002.334 2.333h18.666A2.34 2.34 0 0023.667 17V5.333A2.34 2.34 0 0021.333 3zm0 14h-4.666v-2.334h-2.334V17H2.667V5.333h11.666v2.333h2.334V5.333h4.666V17zm-4.666-7V7.667H19V10h-2.333zm-2.334 0h2.334v2.333h-2.334V10zM19 14.666h-2.333v-2.333H19v2.333z" _fill="#7994BC"/>'
  }
})
