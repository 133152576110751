/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 7,
    height: 14,
    viewBox: '0 0 7 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.3 14a.664.664 0 01-.495-.228L.205 7.55a.84.84 0 010-1.1l5.6-6.222a.65.65 0 01.99 0 .84.84 0 010 1.1L1.69 7l5.105 5.672a.84.84 0 010 1.1A.664.664 0 016.3 14z" _fill="#7F8896"/>'
  }
})
