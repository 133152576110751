/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 27,
    height: 26,
    viewBox: '0 0 27 26',
    data: '<path pid="0" d="M11.934 6.76L8.269 2.53c-.423-.488-1.197-.486-1.688.006l-3.014 3.02c-.897.897-1.154 2.231-.635 3.3a31.64 31.64 0 0014.691 14.712c1.068.519 2.4.262 3.298-.636l3.042-3.047c.493-.493.494-1.272.002-1.695l-4.247-3.645c-.444-.381-1.134-.332-1.58.115l-1.477 1.48a.501.501 0 01-.599.095 15.77 15.77 0 01-5.807-5.814.502.502 0 01.096-.6l1.473-1.475c.447-.449.495-1.142.11-1.587v.001z" _stroke="#048A53" stroke-width="2.167" stroke-linecap="round" stroke-linejoin="round" opacity=".3"/>'
  }
})
