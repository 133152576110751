/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_linkedin-land': {
    width: 41,
    height: 40,
    viewBox: '0 0 41 40',
    data: '<path pid="0" _fill="#361075" d="M.692 0h40v40h-40z"/><path pid="1" d="M16.95 24.615h-2.233v-7.19h2.232v7.19zm-1.118-8.171a1.304 1.304 0 01-.915-2.22 1.293 1.293 0 012.208.915c0 .714-.58 1.305-1.293 1.305zm9.473 8.17h-2.227v-3.5c0-.833-.017-1.903-1.161-1.903-1.161 0-1.339.906-1.339 1.844v3.56h-2.23v-7.19h2.141v.98h.031c.298-.564 1.027-1.16 2.113-1.16 2.26 0 2.675 1.488 2.675 3.42v3.95h-.003z" _fill="#fff"/>'
  }
})
