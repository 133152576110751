/*
App
Created at 09/04/2021 11:44
Author: Khaliq ALI
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import pathResolver from '@/plugins/loader'
import webDevelopment from '../views/web-development.vue'
import mobileDevelopment from '../views/mobile-development.vue'
import designSprint from '../views/design-sprint.vue'
import digitalTransformation from '../views/digital-transformation'
import contact from '../views/contact.vue'
import work from '../views/work.vue'
import Blogs from '../views/Blogs.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Profile from '../views/Profile.vue'
import CreatePost from '../views/CreatePost.vue'
import BlogPreview from '../views/BlogPreview.vue'
import ViewBlog from '../views/ViewBlog.vue'
import EditBlog from '../views/EditBlog.vue'
import Admin from '../views/Admin.vue'
import ComingSoon from '../views/ComingSoon.vue'
import Rodolphe from '../views/Rodolphe.vue'
import ContactForm from '../views/ContactForm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: pathResolver('home', 'index')
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: ComingSoon,
    meta: {
      title: 'Coming Soon'
    }
  },
  {
    path: '/competition',
    name: 'Competition',
    component: pathResolver('competition', 'index')
  },
  {
    path: '/team',
    name: 'Team',
    component: pathResolver('team', 'index')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: contact
  },
  {
    path: '/formation-banques',
    name: 'Rodolphe',
    component: Rodolphe
  },
  {
    path: '/work',
    name: 'Work',
    component: work
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password'
    }
  },
  {
    path: '/automatisation',
    name: 'Automatisation',
    component: webDevelopment
  },

  {
    path: '/blogs',
    name: 'blogs',
    component: Blogs,
    meta: {
      title: 'Blogs'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      title: 'Admin',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/create-post',
    name: 'CreatePost',
    component: CreatePost,
    meta: {
      title: 'Create Post',
      requiresAuth: true
    }
  },
  {
    path: '/post-preview',
    name: 'BlogPreview',
    component: BlogPreview,
    meta: {
      title: 'Preview Blog Post',
      requiresAuth: true
    }
  },
  {
    path: '/view-blog/:blogid',
    name: 'ViewBlog',
    component: ViewBlog,
    meta: {
      title: 'View Blog Post',
      requiresAuth: false
    }
  },
  {
    path: '/edit-blog/:blogid',
    name: 'EditBlog',
    component: EditBlog,
    meta: {
      title: 'Edit Blog Post',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/accompagnement',
    name: 'Accompagnement',
    component: mobileDevelopment
  },
  {
    path: '/modelisation',
    name: 'Modelisation',
    component: designSprint
  },
  {
    path: '/formation',
    name: 'Formation',
    component: digitalTransformation
  },
  {
    path: '/contact-formation',
    name: 'ContactForm',
    component: ContactForm
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // retourner la position désirée
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})

export default router
