/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planning': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<rect pid="0" width="36" height="36" rx="6" _fill="#FFF1FE"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M22.167 9.667c.46 0 .833.373.833.833v.833h.834a2.5 2.5 0 012.5 2.5v10a2.5 2.5 0 01-2.5 2.5H12.167a2.5 2.5 0 01-2.5-2.5v-10a2.5 2.5 0 012.5-2.5H13V10.5a.833.833 0 111.667 0v.833h6.667V10.5c0-.46.373-.833.833-.833zm2.5 8.333H11.334v5.833c0 .46.373.834.833.834h11.667c.46 0 .833-.373.833-.834V18zm-2.5 1.667c.46 0 .833.373.833.833v1.667c0 .46-.373.833-.833.833H20.5a.833.833 0 01-.833-.833V20.5c0-.46.373-.833.833-.833h1.667zM13 13h-.833a.833.833 0 00-.833.833v2.5h13.333v-2.5a.833.833 0 00-.833-.833H23v.833a.833.833 0 11-1.666 0V13h-6.667v.833a.833.833 0 11-1.667 0V13z" _fill="#A02C80"/>'
  }
})
