/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail': {
    width: 18,
    height: 14,
    viewBox: '0 0 18 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.834.333a2.5 2.5 0 012.5 2.5v8.334a2.5 2.5 0 01-2.5 2.5H3.167a2.5 2.5 0 01-2.5-2.5V2.833a2.5 2.5 0 012.5-2.5h11.667zm.833 2.774L9.549 8.46a.833.833 0 01-1.016.063l-.081-.063-6.118-5.353v8.06c0 .46.373.833.833.833h11.667c.46 0 .833-.373.833-.833v-8.06zM14.4 2H3.6L9 6.726 14.4 2z" _fill="#FFBE00"/>'
  }
})
