/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'down': {
    width: 16,
    height: 10,
    viewBox: '0 0 16 10',
    data: '<path pid="0" d="M1 1l7 7 7-7" _stroke="#909090" stroke-width="2"/>'
  }
})
