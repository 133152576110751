<template>
  <div class="contactText-section">
    <div
      class="text-intro py-20 leading-normal lg:px-24 md:px-8"
      data-aos="fade-up" data-aos-offset="200" data-aos-delay="30" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="true" data-aos-anchor-placement="top-bottom"
    >
      <div class="intro flex">
        <div class="debut w-1/2">
          <div class="begin text-5xl text-black font-extrabold">
            {{ $t('fillForm') }}
          </div>
          <div class="begin text-5xl text-black font-extrabold">
            {{ $t('itEasy') }}
          </div>
     <!--      <form ref="form" @submit.prevent="sendEmail">
    <div class="lg:w-1/2 md:w-2/3 mx-auto">
      <div class="flex flex-wrap -m-2">
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="name" class="leading-7 text-sm text-gray-600">Nom</label>
            <input type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-full">
          <div class="relative">
            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
        </div>
        <div class="p-2 w-full">
          <button type="submit" class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Envoyez</button>
        </div>
      </div>
    </div>
  </form> -->
          <form @submit.prevent="sendEmail" class="w-full mt-24">
            <div class="items-center py-2 mb-16">
              <input
                v-model="name"
                placeholder="Votre nom complet"
                for="name"
                id="name" name="name" 
                class="border-b-2 border-l-0 border-r-0 border-t-0 text-2xl font-light appearance-none bg-transparent w-full mr-3 py-2 px-2 focus:outline-none"
                type="text"
                aria-label="Full name"
              >
            </div>
            <div class="items-center py-2 mb-16">
              <input
                v-model="email"
                for="email"
                placeholder="Email"
                id="email"
                name="email"
                class="border-b-2 border-l-0 border-r-0 border-t-0 text-2xl font-light appearance-none bg-transparent w-full mr-3 py-2 px-2 focus:outline-none"
                type="email"
                aria-label="Email"
              >
            </div>
            <div class="items-center py-2 mb-4">
              <textarea
                v-model="message"
                placeholder="Votre message"
                for="message"
                id="message" name="message"
                class="resize-none border-b-2 border-l-0 border-r-0 border-t-0 text-2xl font-light appearance-none bg-transparent w-full mr-3 py-2 px-2 focus:outline-none"
                type="text"
                rows="4"
                aria-label="Description"
              ></textarea>
            </div>
            <div class="items-center py-2 mb-8">
              <div class="p-2 w-full">
                <button type="submit" class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Envoyez</button>
              </div>
            </div>
            <div
              v-if="errors !== ''"
              class="error p-4 bg-red-700 text-white text-xl"
            >
              {{ errors }}
            </div>
          </form> 
        </div>
        <div class="presentation w-1/2 mt-64 ml-16">
          <div class="present-titre font-bold text-5xl text-black mb-8">
            {{ $t('needPresentation') }}
          </div>
          <div class="present-desc text-2xl font-hairline w-3/5 leading-relaxed mb-8">
            {{ $t('youLike') }}
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import FlotteButton from '../flotte-button'
import emailjs from 'emailjs-com';

export default {
  name: 'contact-text',
  components: { FlotteButton },
  data () {
    return {
      name: '',
      email: '',
      message: '',
      errors: '',
      admin: ''
    }
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          name: this.name,
          email: this.email,
          message: this.message
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables.scss";

  section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #023C61;
  }
  section::before {
    content: "";
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background:#1D71B8;
    min-height: 100vh
  }
  .container {
    position: relative;
    min-width: 1100px;
    min-height: 550px;
    display: flex;
    z-index: 1000;
    background: #fff;
  }
  .container .contactInfo {
    position: absolute;
    top: 40px;
    width: 350px;
    height: calc(100% - 80px);
    background: #023C61;
    z-index: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
  }
  .container .contactInfo h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-family: "Roboto Mono", monospace;
  }
  .container .contactInfo ul.info {
    position: relative;
    margin: 20px 0;
  }
  .container .contactInfo ul.info li {
    position: relative;
    list-style: none;
    display: flex;
    margin: 20px 0;
    cursor: pointer;
    align-items: flex-start;
  }
  .container .contactInfo ul.info li span:nth-child(1) {
    width: 30px;
    min-width: 30px;
  }
  .container .contactInfo ul.info li span:nth-child(1) img {
    max-width: 100%;
    filter: invert(1);
  }
  .container .contactInfo ul.info li span:nth-child(2) {
    color: #fff;
    margin-left: 10px;
    font-weight: 300;
    font-family: "Roboto Mono", monospace;
  }
  .container .contactInfo ul.sci {
    position: relative;
    display: flex;
  }
  .container .contactInfo ul.sci li {
    list-style: none;
    margin-right: 15px;
  }
  .container .contactInfo ul.sci li a {
    text-decoration: none;
  }
  .container .contactInfo ul.sci li a img {
    filter: invert(1);
  }
  .container .contactForm {
    position: absolute;
    padding: 70px 50px;
    padding-left: 250px;
    margin-left: 150px;
    width: calc(100% - 150px);
    height: 100%;
    background: #fff;
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
  }
  .container .contactForm h2 {
    color: #023C61;
    font-size: 24px;
    font-weight: 500;
  }
  .container .contactForm .formBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
  }
  .container .contactForm .formBox .inputBox {
    position: relative;
    margin-bottom: 35px;
  }
  .container .contactForm .formBox .inputBox.w50 {
    width: 47%;
  }
  .container .contactForm .formBox .inputBox.w100 {
    width: 100%;
  }
  .container .contactForm .formBox .inputBox input,
  .container .contactForm .formBox .inputBox textarea {
    width: 100%;
    resize: none;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    border: none;
    outline: none;
    border-bottom: 1px solid #777;
  }
  
  .container .contactForm .formBox .inputBox textarea {
    height: 120px;
  }
  .container .contactForm .formBox .inputBox span {
    position: absolute;
    left: 0;
    padding: 5px 0;
    pointer-events: none;
    font-size: 18px;
    font-weight: 300;
    transition: 0.3s;
  }
  .container .contactForm .formBox .inputBox input:focus ~ span,
  .container .contactForm .formBox .inputBox input:valid ~ span,
  .container .contactForm .formBox .inputBox input:not(:placeholder-shown) ~ span,
  .container .contactForm .formBox .inputBox textarea:focus ~ span,
  .container .contactForm .formBox .inputBox textarea:valid ~ span {
    transform: translateY(-20px);
    font-size: 12px;
    letter-spacing: 1px;
    color: #023C61;
    font-weight: 500;
  }
  .container .contactForm .formBox .inputBox input[type="submit"] {
    position: relative;
    cursor: pointer;
    background: #023C61;
    color: #fff;
    border: none;
    max-width: 150px;
    padding: 12px;
  }
  .container .contactForm .formBox .inputBox input[type="submit"]:hover {
    background: #023C61;
  }
  
  /* now, make it responsive */
  @media (max-width: 1200px) {
    .container {
      width: 90%;
      min-width: auto;
      margin: 20px;
      box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
    }
    .container .contactInfo {
      top: 0;
      height: 550px;
      position: relative;
      box-shadow: none;
    }
    .container .contactForm {
      position: relative;
      width: calc(100% - 350px);
      padding-left: 0;
      margin-left: 0;
      padding: 40px;
      height: 550px;
      box-shadow: none;
    }
  }
  @media (max-width: 991px) {
    section {
      background: #1D71B8;
    }
    section::before {
      display: none;
    }
    .container {
      display: flex;
      flex-direction: column-reverse;
    }
    .container .contactForm {
      width: 100%;
      height: auto;
    }
    .container .contactInfo {
      width: 100%;
      height: auto;
      flex-direction: row;
    }
    .container .contactInfo ul.sci {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 600px) {
    .container .contactForm {
      padding: 25px;
    }
    .container .contactInfo {
      padding: 25px;
      flex-direction: column;
      align-items: flex-start;
    }
    .container .contactInfo ul.sci {
      margin-top: 40px;
    }
    .container .contactForm .formBox .inputBox.w50 {
      width: 100%;
    }
  }
  .contactText-section {
    width: 100%;
    overflow: hidden;
  }
  @media screen and (max-width: 600px) {
    .text-intro {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .intro {
      display: block;
    }
    .debut,.present-desc {
      width: 100%;
    }
    .begin {
      font-size: 4rem;
    }
    .presentation {
      width: 100%;
      margin-top: 0;
      margin-left: 0;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .text-intro {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .intro {
      display: block;
    }
    .debut,.present-desc {
      width: 100%;
    }
    .begin {
      font-size: 4rem;
    }
    .presentation {
      width: 100%;
      margin-top: 0;
      margin-left: 0;
    }
  }
</style>
