/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<g opacity=".3" _stroke="#048A53" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M16.25 4H5.75a3.5 3.5 0 00-3.5 3.5v8.75a3.5 3.5 0 003.5 3.5h10.5a3.5 3.5 0 003.5-3.5V7.5a3.5 3.5 0 00-3.5-3.5zM7.5 2.25v3.5m7-3.5v3.5M2.25 9.25h17.5"/></g>'
  }
})
