/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_logo copy': {
    width: 109,
    height: 24,
    viewBox: '0 0 109 24',
    data: '<path pid="0" d="M32.13.14v23.706h4.897c3.3 0 5.89-.32 8.657-2.275 3.123-2.203 4.755-5.615 4.755-9.56 0-3.945-1.668-7.393-4.542-9.561C43.272.495 40.397.14 37.098.14h-4.967zm3.62 3.412h1.561c1.739 0 4.258.142 6.316 1.635C45.33 6.43 46.82 8.883 46.82 12.01c0 3.234-1.597 5.509-3.229 6.753-2.058 1.528-4.506 1.67-6.28 1.67H35.75V3.552zM67.182.112H54.107v23.734h13.075V20.43h-9.45v-6.634h9.45V10.38h-9.45V3.527h9.45V.112zM108.382 3.86a7.832 7.832 0 00-1.751-2.31C105.91.93 104.502 0 102.03 0c-4.291 0-6.694 2.964-6.694 6.065 0 3.446 2.78 5.031 4.669 5.824l2.197.93c1.511.62 3.296 1.585 3.296 3.928 0 2.31-1.648 3.86-3.777 3.86-1.373 0-2.334-.552-2.987-1.378-.549-.724-.96-1.792-.96-2.93l-3.468.759c.137 1.585.686 3.1 1.922 4.48 1.168 1.309 2.747 2.308 5.39 2.308 4.292 0 7.382-2.998 7.382-7.374 0-2.62-1.168-5.031-5.39-6.754l-2.301-.93c-2.231-.897-2.471-2.103-2.471-2.792 0-1.482 1.098-2.757 3.227-2.757.995 0 1.751.241 2.472.827.515.448.824.93 1.03 1.448l2.815-1.655zM70.794.073L80.732 24 90.671.073h-3.694l-6.245 15.67L74.487.073h-3.693zM17.145.073L0 23.835h20.725v-3.82h-3.58V.074zM7.13 20.096l6.09-8.645v8.645H7.13zM24.396 23.835h4.086V.073h-7.648v3.935h3.562v19.827z" _fill="#fff"/>'
  }
})
