<template>
    <div class="contact-page">
        <div class="max-w-md mx-auto mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="text-2xl py-4 px-6 bg-orange-600 text-white text-center font-bold uppercase">
        Formulaire d'inscription
    </div>
    <form ref="form" @submit.prevent="sendEmail" class="py-4 px-6" >
        <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="name">
                Nom
            </label>
            <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text" id="name" name="name"  placeholder="Entrer votre nom">
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="name">
                Prénom
            </label>
            <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="prename" name="prename" type="text" placeholder="Entrer votre  prénom">
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="email">
                Email
            </label>
            <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email" name="email" type="email" placeholder="Enter your email">
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="phone">
                Numéro de téléphone
            </label>
            <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phone" name="phone" type="tel" placeholder="Enter your phone number">
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="service">
                Secteur
            </label>
            <select
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="service" name="service">
                <option value="">Selectionner votre secteur</option>
                <option value="haircut">Banque</option>
                <option value="coloring">Assurance</option>
                <option value="styling">Micro Finance </option>
                <option value="facial">Facial</option>
            </select>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="service1">
                Années d'Expérience dans le Secteur Bancaire (Liste déroulante) :
            </label>
            <select
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="service1" name="service1">
                <option value="">Selectionner votre nombre d'années</option>
                <option value="haircut">20 ans</option>
                <option value="coloring">10 ans</option>
                <option value="styling">5 ans </option>
                <option value="facial">Facial</option>
            </select>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="message">


            Pourquoi souhaitez-vous suivre cette formation en BPM ? (200 mots max) :

            </label>
            <textarea
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message" rows="4" placeholder="Enter any additional information"></textarea>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="message1">

            Quels sont vos objectifs professionnels liés à cette formation ? (150 mots max) :
            </label>
            <textarea
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message1" name="message1" rows="4" placeholder="Enter any additional information"></textarea>
        </div>
        <div class="flex items-center justify-center mb-4">
            <button
                class="bg-orange-600 text-white py-2 px-4 rounded hover:bg-gray-800 focus:outline-none focus:shadow-outline"
                type="submit">
                Soumettre
            </button>
        </div>

    </form>
</div>
    </div>
    </template>
    
    <script>
    import emailjs from 'emailjs-com';
    export default {
      name: 'ContactForm',
      components: { },
      data () {
    return {
      images: { mobile },
      name: '',
      email: '',
      message: '',
      prename:'',
      service: '',
      service1: '',
      message1: '',
      phone: ''

    }
  },
      created: function () {
        document.title = 'BforProcess | Tech Process Company'
        document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
      },
      methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          name: this.name,
          prename: this.prename,
          email: this.email,
          phone: this.phone,
          service: this.service,
          service1: this.service1,
          message: this.message,
          message1: this.message1
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.prename = ''
      this.service = ''
      this.service1 = ''
      this.phone = ''
      this.message1 = ''
      this.email = ''
      this.message = ''
    },
  }
    }
    </script>
    
    <style lang="scss" scoped>
      @import "../assets/style/sass/variables.scss";
    </style>
    