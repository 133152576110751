/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_facebook': {
    width: 33,
    height: 32,
    viewBox: '0 0 33 32',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M16.48.067c-8.836 0-16 7.163-16 16 0 7.925 5.768 14.489 13.332 15.76V19.404h-3.86v-4.47h3.86v-3.296c0-3.825 2.335-5.909 5.748-5.909 1.634 0 3.038.122 3.446.176v3.997l-2.367.001c-1.855 0-2.213.882-2.213 2.176v2.852h4.427l-.577 4.47h-3.85v12.531c7.917-.963 14.054-7.694 14.054-15.87 0-8.833-7.164-15.996-16-15.996z" _fill="#fff"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" transform="translate(.48)" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
