/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_reseau-land': {
    width: 41,
    height: 40,
    viewBox: '0 0 41 40',
    data: '<path pid="0" _fill="#361075" d="M.077 0h40v40h-40z"/><path pid="1" d="M19.308 10.77a8.461 8.461 0 100 16.923 8.461 8.461 0 100-16.924zm0 1.226a7.21 7.21 0 014.803 1.824c-.939 1.238-2.365 2.063-3.794 2.62a37.679 37.679 0 00-2.714-4.243 7.247 7.247 0 011.705-.201zm-3.079.685c.944 1.411 1.858 2.72 2.693 4.201-2.112.547-4.497.875-6.701.881a7.249 7.249 0 014.008-5.082zm8.685 1.976a7.206 7.206 0 011.63 4.526 16.976 16.976 0 00-5.034-.245c-.19-.475-.421-.927-.642-1.413 1.46-.59 3.023-1.598 4.046-2.869zm-5.405 3.345c.181.385.391.793.588 1.21-2.356 1.04-4.863 2.428-6.164 4.863a7.21 7.21 0 01-1.857-5.056c2.523-.012 5-.327 7.433-1.017zm3.824 1.972c1.053 0 2.101.136 3.12.402a7.236 7.236 0 01-3.108 4.86c-.389-1.766-.759-3.456-1.413-5.16a9.713 9.713 0 011.4-.102zm3.158.132l-.016.122.016-.122zm-5.898.3c.667 1.723 1.19 3.631 1.541 5.488a7.212 7.212 0 01-2.826.573 7.204 7.204 0 01-4.44-1.523 10.76 10.76 0 015.725-4.538z" _fill="#fff"/>'
  }
})
