/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_vector1': {
    width: 310.667,
    height: 196,
    viewBox: '0 0 233 147',
    data: '<path pid="0" d="M0 4v3.9l83.8.3c77.9.3 84.1.5 89.2 2.2 19.5 6.5 33.1 19.3 39.8 37.6l2.7 7.5.3 37.7.3 37.8h-3.6c-2 0-3.5.4-3.3.8.7 1.6 10.3 15.1 10.8 15.1.3 0 3.3-3.5 6.7-7.7l6.2-7.7-4-.3-3.9-.3v-35c0-40.1-.6-45.1-7.2-58.9-5.7-12-17.7-23.9-29.8-29.8C172.6-.3 177.4 0 83.8 0H0v4z"/>'
  }
})
