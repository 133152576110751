/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file': {
    width: 16,
    height: 29,
    viewBox: '0 0 16 29',
    data: '<path pid="0" d="M7.813 28.25c-2.109 0-3.896-.733-5.363-2.2-1.467-1.467-2.2-3.254-2.2-5.363V6.25c0-1.513.539-2.807 1.616-3.884S4.237.75 5.75.75s2.807.539 3.884 1.616S11.25 4.737 11.25 6.25v13.063c0 .962-.332 1.776-.997 2.44-.664.665-1.478.997-2.44.997-.963 0-1.777-.332-2.441-.997-.665-.665-.997-1.478-.997-2.44V6.25h2.063v13.063c0 .39.131.715.396.979.263.263.589.395.979.395s.716-.132.98-.396a1.33 1.33 0 00.395-.979V6.25c0-.963-.333-1.776-.997-2.44-.665-.665-1.479-.998-2.441-.998-.963 0-1.776.333-2.44.997-.665.665-.998 1.478-.998 2.441v14.438c0 1.512.539 2.807 1.616 3.884s2.372 1.616 3.885 1.616c1.512 0 2.807-.539 3.884-1.616s1.616-2.372 1.616-3.884V6.25h2.062v14.438c0 2.108-.733 3.895-2.2 5.362s-3.254 2.2-5.363 2.2z" _fill="#9FAFC9"/>'
  }
})
