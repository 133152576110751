/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.75 13.29l-5.043 5.042-1.414-1.414 5.043-5.043-5.043-5.043 1.414-1.414 5.043 5.043a2 2 0 010 2.828z" _fill="#8E8EA9"/>'
  }
})
