/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_instagram-land': {
    width: 41,
    height: 40,
    viewBox: '0 0 41 40',
    data: '<path pid="0" _fill="#361075" d="M.385 0h40v40h-40z"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16.469 13.566a2.517 2.517 0 00-2.518 2.518v6.294a2.517 2.517 0 002.518 2.517h6.293a2.517 2.517 0 002.518-2.517v-6.294a2.517 2.517 0 00-2.518-2.518H16.47zm-3.777 2.518a3.776 3.776 0 013.777-3.776h6.293a3.776 3.776 0 013.776 3.776v6.294a3.776 3.776 0 01-3.776 3.776H16.47a3.776 3.776 0 01-3.777-3.776v-6.294z" _fill="#fff"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M19.954 16.948a2.308 2.308 0 10-.677 4.566 2.308 2.308 0 00.677-4.566zm-2.11-1.131a3.846 3.846 0 113.542 6.827 3.846 3.846 0 01-3.542-6.827z" _fill="#fff"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M21.923 16.154c0-.425.343-.77.765-.77h.008c.423 0 .765.345.765.77 0 .425-.342.77-.765.77h-.008a.767.767 0 01-.765-.77z" _fill="#fff"/>'
  }
})
