<template>
    <div
    class="w-full bg-orange-600 from-teal-400 to-blue-500 flex flex-col justify-center items-center text-white">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 mt-10 ">

        <div class="bg-transparent border text-center">
            <p class="text-5xl px-10 py-5">{{ displayDays }}</p>
            <hr>
            <p class="px-10 py-5">Jours</p>
        </div>

        <div class="bg-transparent border text-center">
            <p class="text-5xl px-10 py-5">{{ displayHours }}</p>
            <hr>
            <p class="px-10 py-5">Heures</p>
        </div>

        <div class="bg-transparent border text-center">
            <p class="text-5xl px-10 py-5">{{ displayMinutes }}</p>
            <hr>
            <p class="px-10 py-5">Mins</p>
        </div>
        <div class="bg-transparent border text-center">
            <p class="text-5xl px-10 py-5">{{ displaySeconds }}</p>
            <hr>
            <p class="px-10 py-5">Secs</p>
        </div>
    </div>
    
</div>
    </template>
    
    <script>
    export default {
      name: 'ContactForm',
      components: { },
      props: [
        "year",
        "month",
        "date",
        "hour",
        "minute",
        "second",
        "millisecond",
      ], 
      data () {
    return {
        messageSent: false ,// Variable pour suivre l'état de l'envoi du message
        displayDays: 0,
        displayMinutes: 0,
        displayHours: 0,
        displaySeconds: 0,
      images: { mobile },
      name: '',
      email: '',
      message: '',
      prename:'',
      service: '',
      service1: '',
      message1: '',
      phone: ''

    }
  },
  computed: {
   _seconds: () => 1000,
   _minutes () {
    return this._seconds * 60
    },
    _hours () {
    return this._minutes * 60
    },
    _days () {
    return this._hours * 24
    },
    end() {
    return new Date (
      this.year,
      this.month,
      this.date,
      this.hour,
      this.minute,
      this.second,
      this.millisecond
    ) 
    },
  },
  mounted(){
    this.showRemaining();
    },
      created: function () {
        document.title = 'BforProcess | Tech Process Company'
        document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
      },
      methods: {
    formatNum: num => (num < 10 ? "0" + num : num),
    showRemaining() {

      const timer = setInterval(() =>{
        const now = new Date();
        const distance = this.end.getTime() - now.getTime();
        if(distance < 0) {
          clearInterval(timer);
          return;
    }
    const days = Math.floor(distance / this._days);
    const hours = Math.floor((distance % this/this._days) / this._hours);
    const minutes = Math.floor((distance % this/this._hours) / this._minutes);
    const seconds = Math.floor((distance % this/this._minutes) / this._seconds);
    this.displayMinutes = this.formatNum(minutes) ;
    this.displaySeconds = this.formatNum(seconds);
    this.displayHours = this.formatNum(hours);
    this.displayDays = this.formatNum(days);

    },1000 )
    },
  }
    }
    </script>
    
    <style lang="scss" scoped>
      @import "../assets/style/sass/variables.scss";
    </style>
    