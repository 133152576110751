<template>
  <!------------------FORM FOR SIGN UP------------------>
  <div id="myForm">
    <form @submit.prevent="sendEmail" class="flex justify-center">
      <div class="flex justify-center px-4 py-4 ">
        <div class="relative w-full cursor-pointer pointer-events-none transition my-auto p-4">
          <div class="w-full py-2 bg-white cursor-default pointer-events-auto dark:bg-gray-800 relative rounded-xl mx-auto max-w-sm">
            <button tabindex="-1" type="button" class="absolute top-2 right-2 rtl:right-auto rtl:left-2" @click="closeForm">
              <svg title="Close" tabindex="-1" class="h-4 w-4 cursor-pointer text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
              <span class="sr-only">
                Close
              </span>
            </button>
            <div class="space-y-2 justify-center p-2">
              <div class="p-4 space-y-2 text-center dark:text-white">
                <h2 class="text-xl font-bold tracking-tight" id="page-action.heading">
                  Obtenir la fiche
                </h2>
                <p class="text-gray-500">
                  Veillez remplir le Formulaire pour l'obtention
                </p>
              </div>
            </div>
            <div class="space-y-2">
              <div aria-hidden="true" class="border-t dark:border-gray-700 px-2"></div>
            </div>
            <div class="mb-4 items-center mx-2 px-4 justify-center">
              <label class="block text-gray-700 font-bold mb-2" for="name">
                Nom
              </label>
              <input v-model="formData.name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" id="name" name="name" placeholder="Entrer votre nom" required>
            </div>
            <div class="mb-4 items-center mx-2 px-4 justify-center">
              <label class="block text-gray-700 font-bold mb-2" for="prename">
                Prénom
              </label>
              <input v-model="formData.prename" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="prename" name="prename" type="text" placeholder="Entrer votre prénom" required>
            </div>
            <div class="mb-4 items-center mx-2 px-4 justify-center">
              <label class="block text-gray-700 font-bold mb-2" for="email">
                Email
              </label>
              <input v-model="formData.email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" name="email" type="email" placeholder="Entrer votre email" required>
              <span v-if="!isEmailValid" class="text-red-500">Veuillez entrer une adresse email valide</span>
            </div>
            <div class="flex items-center mx-2 px-4 justify-center mx-2 mb-4">
              <button :disabled="!isFormValid" class="bg-orange-600 text-white py-2 px-4 rounded hover:bg-gray-800 focus:outline-none focus:shadow-outline" type="submit" @click="closeForm">
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  </template>
  
  <script>
  
  /*Import Images*/
  /*------------------IMAGES FORM ------------------*/
  import close from '../../assets/images/img_close.webp'

  import emailjs from 'emailjs-com';

  
  export default {
   
    name: "index",
    data () {
      return {
        formData: {
        name: '',
        prename: '',
        email: ''
      },
        icons: {
        },
        images:{
          close
        },
        name: '',
      email: '',
      message: '',
      prename:'',
      }
    },
    methods: {
      sendEmail() {
      // Logique pour envoyer l'email
      console.log('Sending email...', this.formData);
    },
    closeForm() {
      // Logique pour fermer le formulaire
      console.log('Closing form...');
    },
      closeForm() {
        document.getElementById("myForm").style.display = "none";
      },
      sendEmail(e) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          name: this.name,
          prename: this.prename,
          email: this.email,
          phone: this.phone,
          service: this.service,
          service1: this.service1,
          message: this.message,
          message1: this.message1
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.prename = ''
      this.service = ''
      this.service1 = ''
      this.phone = ''
      this.message1 = ''
      this.email = ''
      this.message = ''
    },
    },
    computed: {
    isEmailValid() {
      // Vérifie si l'email a un format valide
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(this.formData.email);
    },
    isFormValid() {
      // Vérifie si tous les champs requis sont remplis et que l'email est valide
      return this.formData.name !== '' && this.formData.prename !== '' && this.isEmailValid;
    }
  },
    mounted() {
    }
  }
  </script>
  
  <style lang="scss" scoped>
@import "../../assets/style/sass/variables";
  
  /* The popup form - hidden by default */
  #myForm {
      width: 100%;
      height: 100%;
      top: 0;
      left:0;
      position: fixed;
      display: none;
      background-color:rgba(22, 22, 22, 0.6);
  }
  
  form{
    position: fixed;
      top: 2%;
      margin-left: 35%;
      padding: 4px 4px 4px 4px ;
  }

  </style>
  
  
  
  