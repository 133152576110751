/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'finance': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.85 2.316a.534.534 0 00-.534.534v12.2a.534.534 0 00.534.534h18.3a.534.534 0 00.534-.534V2.85a.534.534 0 00-.534-.534H2.85zM1.07 1.071A2.516 2.516 0 012.85.333h18.3a2.516 2.516 0 012.517 2.517v12.2a2.516 2.516 0 01-2.517 2.517H2.85A2.516 2.516 0 01.333 15.05V2.85c0-.668.265-1.308.737-1.78zm2.313 2.313h3.28v1.982H5.365v1.296H3.383V3.384zm15.251 1.982h-1.296V3.384h3.278v3.278h-1.982V5.366zM12 6.891a2.059 2.059 0 100 4.118 2.059 2.059 0 000-4.118zm-2.858-.799a4.041 4.041 0 115.716 5.716 4.041 4.041 0 01-5.716-5.716zm-5.759 5.146h1.983v1.296h1.296v1.982H3.383v-3.278zm17.233 0v3.279h-3.278v-1.983h1.296v-1.296h1.982zm2.822 7.396v1.983H.562v-1.983h22.876zm0 3.05v1.983H.562v-1.983h22.876z" _fill="#7994BC"/>'
  }
})
