<template>
<div class="contact-page">
  <contact-text />
</div>
</template>

<script>
import ContactText from '../components/helpers/contact/contact-text'
export default {
  name: 'contact',
  components: { ContactText },
  created: function () {
    document.title = 'BforProcess | Tech Process Company'
    document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/sass/variables.scss";
</style>
