/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_shape3': {
    width: 464,
    height: 454,
    viewBox: '0 0 464 454',
    data: '<path pid="0" d="M56.47 69.956c96.335-107.38 275.145-84.968 368.544 25.135 60.586 71.423 36.229 214.954 19.72 283.973-5.893 24.637-24.522 43.665-49.008 50.158-74.741 19.818-238.562 52.058-312.837-21.051-94.35-92.869-114.89-239.601-26.42-338.215z" _fill="#F6F6F6"/>'
  }
})
