/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_linkedin': {
    width: 41,
    height: 41,
    viewBox: '0 0 41 41',
    data: '<circle pid="0" opacity=".4" cx="20.813" cy="20.524" r="19.203" _stroke="#E0E0E0" stroke-width="1.422"/><path pid="1" d="M17.705 24.792v-7.198h-2.439v7.198h2.44zm-1.219-8.18c.85 0 1.38-.554 1.38-1.244-.016-.707-.53-1.244-1.364-1.244-.834 0-1.38.537-1.38 1.244 0 .69.53 1.243 1.348 1.243h.016zm2.57 8.18h2.439v-4.02c0-.214.016-.43.08-.583.176-.43.578-.875 1.252-.875.882 0 1.235.66 1.235 1.628v3.85h2.44v-4.127c0-2.21-1.204-3.24-2.809-3.24-1.316 0-1.893.722-2.214 1.213h.016v-1.044h-2.44c.032.676 0 7.198 0 7.198z" _fill="#000"/>'
  }
})
