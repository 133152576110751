<template>
  <div class="reset-password">
      <Modal v-if="modalActive" v-on:close-modal="closeModal" />
      <Loading v-if="loading" />
      <div class="form-wrap">
          <form action="" class="reset">
              <h2>Reset Password</h2>
              <p class="login-register">Forgot your Password?   <router-link class="router-link" :to="{ name: 'Login' }">login</router-link></p>
              <div class="inputs">
                  <div class="input">
                      <input type="text" placeholder="Email" v-model="email" />
                      <email class="icon" />
                  </div>
              </div>
              <button @click.prevent="resetPassword">Reset</button>
              <div class="angle"></div>
          </form>
          <div class="background"></div>
      </div>
  </div>
</template>


<script>
import email from "../assets/cons/envelope-regular.svg"
import Loading from '../components/Loading.vue';
import Modal from '../components/Modal.vue';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
export default {
    name: 'ForgotPassword',
    components: { Modal, Loading }, 
    data() {
        return {
            email: null,
            modalActive: false,
            modalMessage: "",
            Loading: null
        }
    },
    components: {
        email,
        Modal,
        Loading,
    },
    methods: {
        resetPassword() {
            this.loading = true;
            firebase
        .auth()
        .sendPasswordResetEmail(email.value)
        .then(() => {
          modalMessage.value = "si votre email exist , vous recevrez un mail";
          loading.value = false;
          modalActive.value = true;
        })
        .catch((err) => {
          modalMessage.value = err.message;
          loading.value = false;
          modalActive.value = true;
        });
        },
        closeModal() {
            this.modalActive = !this.modalActive,
            this.email = ''
        },
    },
};
</script>

<style lang="scss" scoped>
.reset-password {
    position: relative;
    .form-wrap {
        .reset {
            h2 {
                margin-bottom: 8px;
            }
            p {
                text-align: center;
                margin-bottom: 32px;
            }
        }
    }
}
</style>