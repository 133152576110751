/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 7,
    height: 14,
    viewBox: '0 0 7 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.7 14c.18 0 .359-.076.495-.228l5.6-6.222a.84.84 0 000-1.1L1.195.228a.65.65 0 00-.99 0 .84.84 0 000 1.1L5.31 7 .205 12.672a.84.84 0 000 1.1A.664.664 0 00.7 14z" _fill="#7F8896"/>'
  }
})
