import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyB7vLJUhREoaBbR5P1P50MGLmrsmi6mzUA',
  authDomain: 'bfrprocesss.firebaseapp.com',
  projectId: 'bfrprocesss',
  storageBucket: 'bfrprocesss.appspot.com',
  messagingSenderId: '958968703104',
  appId: '1:958968703104:web:58e114737ae59ab1421e65',
  measurementId: 'G-KHSSBLY4D9'
}

const fireBaseApp = firebase.initializeApp(firebaseConfig)
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { timestamp }
export default fireBaseApp.firestore()
